import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import {StepIconProps} from '@mui/material/StepIcon';
import {AccountCircle, ArrowBack, ArrowForward, AttachMoney, UploadFileSharp} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {RenderContainer} from '../..';
import {Form, Formik} from 'formik';
import {WLOCreditAPIService} from '../../../services';
import toast from 'react-hot-toast';
import {useIntl} from "react-intl";
import Moment from 'moment';
import {AppRoutes} from "../../../Router";
import {useNavigate} from "react-router-dom";
import {useRequestContext} from '../../../hooks/useRequestContext';
import {useLoginContext} from '../../../hooks/useLoginContext';
import _ from 'lodash';
import {ColorlibConnector, ColorlibStepIconRoot, Item} from "../../../utils/utils";
import { WloCreditoService } from '../../../types';
import { useState } from 'react';

interface WizardsProps {
    steps: ({key: string, label: string, initialValues: any, validationSchema: any})[];
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function Wizard(props: WizardsProps) {
    const { steps,setPopup } = props;
    const {user} = useLoginContext();
    const [activeStep, setActiveStep] = React.useState(0);
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const intl = useIntl();
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);


    const handleNext = async (values: any) => {
        if (activeStep === 0) {
           
            try {
                    if (!request.errorConsulta) {
                        if (!request.numSol) {
                            
                            const dateSolicitud = Moment(new Date()).format('MM/DD/YYYY');
                            let codVendedor = sessionStorage.getItem('User');
                            let cVendedor;
                            let idProducto=sessionStorage.getItem('idProdc');
                            if(JSON.parse(codVendedor!).idProfile =='2'){
                                 cVendedor = ''
                            }else{
                                cVendedor = JSON.parse(codVendedor!).idUser;
                            }
                            const result = await WLOCreditAPIService.addSolicitud({
                                Pws_ident_vende: cVendedor,
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Val_cuoini: values.Pws_Val_cuoini,
                                Pws_Val_cuo: request.valueOfQuote,
                                Pws_Val_finan: request.valueToFinance,
                                Pws_Correo: values.Pws_Correo,
                                Pws_Apellidos1: values.Pws_Apellidos1,
                                Pws_Apellidos2: values.Pws_Apellidos2,
                                Pws_Telefono1: values.Pws_Telefono1,
                                Pws_Telefono2: values.Pws_Telefono2,
                                Pws_Nombres: values.Pws_Nombres,
                                Pws_Val_pla: values.pws_nro_cuotas,
                                Pws_fec_solicitud: dateSolicitud,
                                Pws_Tip_Estado: "1", // Valor constante 1 Simulador S
                                Pws_Tip_Perfil: user.idProfile,
                                Pws_Tip_Person: "N",
                                Pws_linea_credito:values.pws_cod_credi,
                                Pws_Periodic:values.Pws_Val_pla, //Valor que pide el ws pero a nivel de UI no existe
                                Pws_Cod_prod:String(1),
                            });
                            console.dir(result)
                            if (result.payload.result === "1") {
                                const solicitudResult = await WLOCreditAPIService.addSolicitud({
                                    Pws_Identificacion: values.Pws_Identificacion,
                                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
                                });
                                console.log(solicitudResult);
                                
                                const numSolicitud = solicitudResult.payload.data.Datos_Sol.Datos_Sol[0].Pws_num_solicitud;
                                setRequest({
                                    ...request,
                                    numIdentification: values.Pws_Identificacion,
                                    typeIdentification: values.Pws_Tip_Identificacion,
                                    numSol: numSolicitud,
                                    email: values.Pws_Correo,
                                    dateSol: dateSolicitud
                                });
                                ////
                                toast.success('Hemos generado una nueva solicitud');
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }else{
                                toast.error('No es posible continuar con el proceso');

                            }
                        }
                        else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                    else {
                        toast.error('No es posible validar su identidad');
                    }
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }

      
        }

        if (activeStep === 1) {            
            try {
                const personaRequest = {
                    Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                    Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
                    Pws_Tip_Perfil:request.searchANI?"2":request.personCygnus?.Pws_Tip_Perfil,
                    Pws_Tip_person: values.Pws_Tip_person,
                    Pws_Tip_ocupa: values.Pws_Tip_ocupa,
                    Pws_Nombres: values.Pws_Nombres!,
                    Pws_Apellidos1: values.Pws_Apellidos1! ,
                    Pws_Apellidos2: values.Pws_Apellidos2! ,
                    Pws_Telefono1: values.Pws_Telefono1,
                    Pws_Telefono2: values.Pws_Telefono2,
                    Pws_Correo: values.Pws_Correo,
                    Pws_Fec_expe:  Moment(values.Pws_Fec_expe!).format('MM/DD/YYYY') ,
                    Pws_Lugar_exp: values.Pws_Lugar_exp! ,
                    Pws_Fec_nacime:  Moment(values.Pws_Fec_nacime!).format('MM/DD/YYYY'),
                    Pws_Estado_Civil: values.Pws_Estado_Civil,
                    Pws_Direccion_res: values.Pws_Direccion_res,
                    Pws_Departamento: values.Pws_Departamento,
                    Pws_Ciudad_res: values.Pws_Ciudad_res,
                    Pws_Genero: values.Pws_Genero,
                    Pws_Estrato_per: values.Pws_Estrato_per,
                    Pws_Per_cargo: values.Pws_Per_cargo,
                    Pws_Tip_vivienda: values.Pws_Tip_vivienda,
                    Pws_Niv_estudio: values.Pws_Niv_estudio,
                    Pws_Nom_empre: values.Pws_Nom_empre,
                    Pws_fec_ingemp:  Moment(values.Pws_fec_ingemp!).format('MM/DD/YYYY'),
                    Pws_Fideliza: values.Pws_Fideliza,
                    Pws_Tip_contra: values.Pws_Tip_contra,
                    Pws_Ant_labo: values.Pws_Ant_labo,
                    Pws_Car_emp: values.Pws_Car_emp,
                    Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
                    Pws_Direc_emp: values.Pws_Direc_emp,
                    Pws_Ciud_emp: values.Pws_Ciud_emp,
                    Pws_tel_emp1: values.Pws_tel_emp1,
                    Pws_tel_emp2: values.Pws_tel_emp2,
                }
                    if (request?.searchANI) {
                            const result = await WLOCreditAPIService.addSolicitudPersona(personaRequest);
                            if (!_.isEmpty(result.payload)) {
                                if (result.payload.result > 0) {
                                    toast.success('Hemos generado una nueva solicitud persona');
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                } else {
                                    toast.error(result.payload.message);
                                }
                            }
                            
                    } else {
                            const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion});
                            if (!_.isEmpty(resultConsultaPersona.payload)) {
                                const personaConsultaResult = resultConsultaPersona.payload.data.datos_persona.Datos_persona[0];
                                const infoUpdatePerson = {...personaRequest, Pws_i_codigo: personaConsultaResult ? personaConsultaResult.Pws_i_codigo : 0};
                                const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                                if (resultUpdatePersona.payload.result > 0) {
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    //Preguntar si efectivamente se creo.
                                    toast.success('Hemos actualizado su solicitud persona');
                                } else {
                                    toast.error('No hemos podido completar tu solicitud.');
                                }
                            }
                    }
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }

        }

        if (activeStep === 2) {
            try {
                const operFinancieras = {
                    s_num_solicitud: request.numSol!,
                    s_identificacion: values.Pws_Identificacion,
                    s_tipo_identificacion: values.Pws_Tip_Identificacion,
                    s_ingreso_principal: values.s_ingreso_principal,
                    s_otros_ingresos: values.s_otros_ingresos,
                    s_otros_egresos: values.s_otros_egresos,
                    s_arriendo: values.s_arriendo,
                    s_concep_otr_ingre: values.s_concep_otr_ingre,
                    s_declarante_ren: values.s_declarante_ren ? "1" : "0",
                    s_moneda_ext: values.s_moneda_ext ? "1" : "0",
                    s_monext_oper: values.s_monext_oper,
                    s_tip_monext: values.s_tip_monext,
                    s_cuent_ext: values.s_cuent_ext,
                    s_cuen_extban: values.s_cuen_extban,
                    s_cuen_extnum: values.s_cuen_extnum,
                    s_cuen_extpais: values.s_cuen_extpais,
                    s_cuen_extciudad: values.s_cuen_extciudad
                }
                const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);
                const peps = {
                    s_num_solicitud: request.numSol!,
                    s_identificacion: values.Pws_Identificacion,
                    s_tipo_identificacion: values.Pws_Tip_Identificacion,
                    s_pep_recpublic: values.s_pep_recpublic ? "1" : "0",
                    s_pep_poderpublic: values.s_pep_poderpublic ? "1" : "0",
                    s_pep_reconpublic: values.s_pep_reconpublic ? "1" : "0",
                    s_pep_pubexpue: values.s_pep_pubexpue ? "1" : "0",
                    s_pep_seggraconsa: values.s_pep_seggraconsa ? "1" : "0",
                    s_pep_nompepseg: values.s_pep_nompepseg,
                    s_pep_paren: values.s_pep_paren,
                    s_pep_identif: values.s_pep_identif,
                }
                const result = await WLOCreditAPIService.addPeps(peps);
               //Preguntar si efectivamente se creo.
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                toast.success('Hemos añadido la información financiera a su solicitud.');
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }
        }

        if (activeStep === 3) {
            if(valorCheck()){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }else{
                    toast.error('Debes completar los documentos Adjuntos');
            }
        }
    };
    const valorCheck = (): boolean => {        
        let letValorAceptado = true;
        const fileInputs = document.getElementsByName("file-input");
        for (let i = 0; i < fileInputs.length; i++) {
            const valor = fileInputs[i] as HTMLInputElement;
            if (valor.files && valor.files.length === 0) {
                letValorAceptado = false; // Si algún elemento tiene 0 archivos, cambia a falso (false)
                break; // Salimos del bucle, ya que no es necesario seguir verificando
            }
        }
    
        return letValorAceptado;
    };
    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const onSubmit = async (values: any, formikBag: { setSubmitting: any; }) => {
        const {setSubmitting} = formikBag;
        if (!isLastStep()) {
            if(activeStep == 0){
                if(values.Pws_Correo==values.mail_confirmation){  
                    setSubmitting(false);
                    handleNext(values).then();
                    return;
                }else{
                    toast.error('No puede continuar los correos no coincide');
        
                }
            }else{
                setSubmitting(false);
                handleNext(values).then();
                return;
            }
           
        } else {
                const cygnus=await WLOCreditAPIService.grabar_cignus({
                    Pws_Identificacion :values.Pws_Identificacion,
                    Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
                    Pws_Tip_Perfil:"2"
                })
                // if(Number(cygnus.payload.result)>0){
                //     const dateSolicitud = Moment(new Date()).format('MM/DD/YYYY');
                    
                    // const creaCreditos=await WLOCreditAPIService.createCredito({
                    //     pws_monto: values.Pws_Val_finan,
                    //     pws_nro_cuotas: values.pws_nro_cuotas!,
                    //     pws_fecha_sol: dateSolicitud,
                    //     pws_val_recog: "",
                    //     pws_fecha_prox_pag: "",
                    //     pws_cod_credi: values.pws_cod_credi,
                    //     pws_form_plazo: values.Pws_Val_pla,
                    //     pws_identific: values.Pws_Identificacion,
                    //     pws_form_periodic: values.Pws_Val_pla,
                    //     pws_val_tf1: "",
                    //     pws_fec_tf1: "",
                    //     pws_val_tf2: "",
                    //     pws_fec_tf2: "",
                    //     pws_val_tf3: "",
                    //     pws_fec_tf3: "",
                    //     pws_num_solicitud: request.numSol!,
                    //     pws_estado_credito: "S",
                    //     pws_estado_solicitud: "S",
                    //     pws_descripcion:request.numSol!,
                    //     pws_porcentajes_radic: ""
                    // })
                    // if(creaCreditos.payload.message=="SUCCESS"){
                    //     if(request.isRotativo){
                    //        const addCupos= await WLOCreditAPIService.addCupo({
                    //         Pws_Identificacion:values.Pws_Identificacion,
                    //         Pws_num_Tip_Ident:values.Pws_Tip_Identificacion,
                    //          Pws_Solicitud:request.numSol!
                    //         })
                    //     }
                        try {
                            const persona={
                                cOcupacion: values.Pws_Tip_ocupa,
                                cSalario: parseInt(values.s_ingreso_principal.replace(/,/g, '')),
                                cEmpresaLabora: values.Pws_Nom_empre,
                                cAntiguedadLaboral: values.Pws_Ant_labo,
                                cCargoEmpresa: values.Pws_Car_emp,
                                cDireccionLaboral: values.Pws_Direc_emp,
                                iEstadoCivil: values.Pws_Estado_Civil,
                                cIdentificacion: values.Pws_Identificacion,
                                cNombres: values.Pws_Nombres,
                                cApellidos: values.Pws_Apellidos1 + " " + values.Pws_Apellidos2,
                                cDireccionResidencia: values.Pws_Direccion_res,
                                cNumTelFijo: "",
                                cNumCelular: values.Pws_Telefono1,
                                cEstado: "ACT",
                                iTipoIdent: parseInt(values.Pws_Tip_Identificacion),
                                cEmail: values.Pws_Correo,
                                cGenero: values.Pws_Genero == "F" ? 1 : 0 ,
                                iCiudad: values.Pws_Ciudad_res ,
                                tipoPerfil: 2
                              }
                            setIsLoadingInfo(true);
                            // if(usuarioWeb){
                                try{
                                    const savePerson = await WLOCreditAPIService.savePerson(persona);
                                    console.log(savePerson);
                                } catch (error) {
                                    console.log(error);
                                    toast.error('En este momento no podemos atender su solicitud');
                                    setTimeout(() => {
                                    }, 4000);
                                }
                                // }
                                const usuarioWeb = await WLOCreditAPIService.buscarPersonaWeb(values.Pws_Tip_Identificacion,values.Pws_Identificacion);
                                console.log(usuarioWeb);
                                const fechaSolicitud = Moment(new Date()).format('YYYY-MM-DD');
                                const solWeb = await WLOCreditAPIService.crearSolCredWeb({
                                    codPersona : usuarioWeb.listUsuarios[0].IPersona.ICodigo,
                                    codCredito : values.pws_cod_credi,
                                    numPoliza:values.Num_poliza,
                                    montoSolicitud:values.Pws_Val_monto.replace(/,/g, ''),
                                    plazo: values.pws_nro_cuotas,
                                    valorCuota: values.Pws_Val_cuo,
                                    codPeriodo: '1',
                                    codUsuarioCreador:usuarioWeb.listUsuarios[0].ICodigo,
                                    lineaCred: values.pws_cod_credi ,
                                })
                                const cuenta ={
                                        sTitular: values.esTitular ? "S" : "N",
                                        nombreTitular: values.Pws_Nombres + " " + values.Pws_Apellidos1 + " " + values.Pws_Apellidos2,
                                        codSolicitud: solWeb.codigoRadicado,
                                        sTipoIdent: parseInt(values.Pws_Tip_Identificacion),
                                        sNumDoc: values.Pws_Identificacion,
                                        cEmailCod: "",
                                        cNumCelular: values.Pws_Telefono1,
                                        cNumTelefono: values.Pws_Telefono1,
                                        numeroCuenta: "PSE",
                                        banco: "PSE",
                                        codTipoCuenta: values.tipoCuenta,
                                        codUsuarioCreador: usuarioWeb.listUsuarios[0].ICodigo
                                }
                                const crearCuenta = await WLOCreditAPIService.crearInfoCuenta(cuenta)
                                const radicarSolweb = await WLOCreditAPIService.radicarSolWeb(solWeb.codigoRadicado, usuarioWeb.listUsuarios[0].ICodigo ,'')
                                const savePagare = await WLOCreditAPIService.savePagare({
                                    personaDeudor:persona,
                                    requiereCodeudor: false 
                                });
                                const pagare = await WLOCreditAPIService.findPagare(values.Pws_Identificacion)
                                const firmaPagare = await WLOCreditAPIService.iniciarProcesoFirma(pagare[0])
                                setIsLoadingInfo(false);
                                toast.success('Hemos finalizado con su solicitud.');
                            //   }
                            // const emailInfo = {
                            //     email: String(request.email),
                            //     dateSolicitud: new Date().toDateString(),
                            //     numsol:String(request.numSol!),
                            //     ps_cod_lin:values.pws_cod_credi,
                            //     ps_tipoCliente:values.Pws_Tip_ocupa,
                            //     radicado:creaCreditos.payload.data.R_numRadic,
                            //     Ps_tip_deucode:"1",
                            //     rotativo:request.isRotativo
                            // }
                            // const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
                            navigate(AppRoutes.CONFIRMATION,{replace: true})
                            // if (resultEnvioCorreo) {
                                
                            //     toast.success('Se ha enviado con éxito el email con la cotización');
                        }
                            catch (error) {
                            console.log(error);
                            
                            toast.error('En este momento no podemos atender su solicitud');
                            setTimeout(() => {
                            }, 4000);
                        }
                    // }else{
                    //     toast.error("Error al creCredito")
                    // }
                // }else{
                //     toast.error(cygnus.payload.message);           
        }
        setTimeout(() => {
            setSubmitting(false);
        }, 500);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            navigate(AppRoutes.POLICIES, {replace: true});
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }

    };

    function ColorlibStepIcon(props: StepIconProps) {
        const {active, completed, className} = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <Check/>,
            2: <AccountCircle/>,
            3: <AttachMoney/>,
            4: <UploadFileSharp/>
        };

        return (
            <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const initialValues = steps.reduce(
        (values, {initialValues}) => ({
            ...values,
            ...initialValues
        }),
        {}
    );

    const validationSchema = steps[activeStep].validationSchema

    return (

        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({isSubmitting, values, errors}) => (
                    <>
                        <Form>
                            <Stack direction={{xs: 'column', sm: 'row'}}
                                   spacing={{xs: 1, sm: 1, md: 4}} justifyContent="space-between">
                                <Item elevation={0}>
                                    <div className="d-none d-md-none d-lg-block d-xl-block">
                                        <Fab variant="extended" color="neutral" aria-label="add"
                                             onClick={handleBack} style={{float:"left"}} className="hooverButtonRed">
                                            <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                                            {intl.formatMessage({id: "button_back"})}
                                        </Fab>
                                    </div>
                                    <div className="d-block d-md-block d-lg-none d-xl-none">
                                        <Fab variant="extended" color="neutral" aria-label="add" 
                                             onClick={handleBack} className="hooverButtonRed"> 
                                            <ArrowBack className="hooverButtonRedsvg"/>
                                            {intl.formatMessage({id: "button_back"})}
                                        </Fab>
                                    </div>
                                </Item>
                                <Item elevation={0}>
                                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                                        {steps.map((item) => (
                                            <Step key={item.label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                    <p className="step">{item.label}</p>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Item>
                                <Item elevation={0}>
                                    <div className="d-none d-md-none d-lg-block d-xl-block">
                                        {Object.keys(errors).length > 0?<>
                                            <Fab style={{paddingLeft:"25px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                             type="button" onClick={()=>{toast.error("Complete la data");console.log(errors)}}className="hooverButtonsubmit">
                                            {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                            <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                        </Fab>
                                        </>:<>
                                        <Fab style={{paddingLeft:"25px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                             type="submit"className="hooverButtonsubmit">
                                            {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                            <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                        </Fab>
                                        </>}
                                       
                                    </div>
                                </Item>
                            </Stack>
                            <RenderContainer errors={errors} values={values} step={steps[activeStep].key as any} setPopup={setPopup}/>
                            <Item elevation={0}>
                                
                                <div className="d-block d-md-block d-lg-none d-xl-none">
                                {Object.keys(errors).length > 0?<>
                                    <Fab variant="extended" style={{paddingLeft:"25px"}} color="error" aria-label="add" disabled={isSubmitting}
                                        type="button" onClick={()=>{toast.error("Complete la data");console.log(errors)}} className="hooverButtonsubmit">
                                        {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                        <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                    </Fab>
                                        </>:<>
                                        <Fab style={{paddingLeft:"25px"}} variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                         type="submit" className="hooverButtonsubmit">
                                        {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                        <ArrowForward sx={{mr: 1}} className="hooverButtonRedsvgsubmit"/>
                                    </Fab>
                                        </>}
                                 
                                </div>
                            </Item>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
}