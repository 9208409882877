import React, { useEffect, useRef, useState } from 'react';
import './dc.scss';
import Fab from '@mui/material/Fab';
import { FUllPill } from '../Pill/Pill';
import { Doc, Person, File, RedCheck, Edit, GreenCheck, GreenCheckBig, Documentos, Documentosred, Redx } from '../../../assets/icons/component';
import { ReferenceTable, CodeudorTable } from '../../shared/ReferenceDocument/ReferenceDocument';
import { Rating } from '../RatingScore';
import { AppRoutes } from '../../../Router';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { WLOCreditAPIService, AdminService } from '../../../services';
import { WloCreditoService } from '../../../types';
import toast from 'react-hot-toast';
import { Switch } from 'formik-mui';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { typeIdentification, estractos, cities, Ocupaciones } from '../../../__mocks__/data';
import { useRequestContext } from '../../../hooks/useRequestContext';
import { useLoginContext } from '../../../hooks/useLoginContext';
import ParseNumberThousand from '../../shared/Utilities/ParseNumberThousand';
import Moment from 'moment';
import { ArrowBack, Search, Try } from '@mui/icons-material';
import ParseNumberSmaller from '../Utilities/ParseNumberSmaller';
import ClockLoader from 'react-spinners/ClockLoader';
import { Field, Form, Formik } from 'formik';
import { env } from 'process';
import axios from 'axios';
import { FormSpouseCodeudor } from '../../Steps/ApplicantInfo/ModalSpouse/FormSpouseCodeudor';
interface infoProps {
    info: {
        bg1: string;
        text: string;
        bg2: string;
        abcilon: string;
        revisado: Boolean;
    };
    i: number;
    datodesembolso: Boolean;
}

const data = [
    {
        bg1: '#00908E',
        text: 'Información del crédito',
        bg2: 'none',
        abcilon: '',
        revisado: true,
    },
    {
        bg1: '#00908E',
        text: 'Información personal',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información financiera',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Documentos de la solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Referencias',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información codeudor Propetario',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información codeudor Requerido',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información de verificación',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Calificar la Solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Documentos de Garantia',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
];

const dataGestor = [
    {
        bg1: '#00908E',
        text: 'Información del crédito',
        bg2: 'none',
        abcilon: '',
        revisado: true,
    },
    {
        bg1: '#00908E',
        text: 'Información personal',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información financiera',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Documentos de la solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Referencias',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información codeudor Propetario',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información codeudor Requerido',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Información de verificación',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Consulta a terceros',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Calificar la Solicitud',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
    {
        bg1: '#00908E',
        text: 'Documentos de Garantia',
        bg2: '#F13A00',
        abcilon: '!',
        revisado: false,
    },
];

const CreditSideBar: React.FC<infoProps> = ({ info, i, datodesembolso }) => {
    const gotobox = (event: any) => {
        document.getElementsByClassName('box')[event.currentTarget.dataset.id].scrollIntoView();
    };
    let mostrar = true;
    if (i == 11) {
        if (datodesembolso) {
            mostrar = true;
        } else {
            mostrar = false;
        }
    }
    if (mostrar) {
        return (
            <ul onClick={gotobox} data-id={i}>
                <li style={{ background: info.bg1 }} className="rank">
                    {i + 1}
                </li>
                <li className="text">{info.text}</li>
                <li style={{ background: info.bg2 }} className="abcilon">
                    {info.abcilon}
                </li>
            </ul>
        );
    } else {
        return <></>;
    }
};

interface Box1Prop {
    dato: any;
    person: any;
    setDatosP: React.Dispatch<React.SetStateAction<any>>;
    setAction: React.Dispatch<React.SetStateAction<boolean>>;
    onDatosPChange: (newDatosP: any) => void;
}

export const Box1 = (props: Box1Prop) => {
    const { dato, person, setAction } = props;
    const [datosP, setDatosP] = useState<any>();
    const [listCredit, setListcredit] = useState([]);
    const num_solicitud = sessionStorage.setItem('num_sol', dato.Pws_num_solicitud);
    const [one] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getValueListCreditos();        
    }, [one]);
    const getValueListCreditos = async () => {
        const valor = await AdminService.getlineascredito();
        if (valor.payload.data.ListLineaCredito.Datos_LineasCredito.length > 0) {
            setListcredit(valor.payload.data.ListLineaCredito.Datos_LineasCredito);
        }
    };
    const forwardingFirma = async () => {
        let rotativo = false;
        const lista =
            listCredit.filter((valor: any) => valor.s_codigo == Number(dato.Pws_linea_credito)).length > 0
                ? listCredit.filter((valor: any) => valor.s_codigo == dato.Pws_linea_credito)[0]['s_rotativo']
                : '0';
        if (Number(lista) == 1) {
            rotativo = true;
        }
        const emailInfo = {
            email: person.Pws_Correo,
            dateSolicitud: new Date().toDateString(),
            numsol: dato.Pws_num_solicitud,
            ps_cod_lin: dato.Pws_linea_credito,
            ps_tipoCliente: person.Pws_Tip_ocupa,
            radicado: dato.Pws_num_radic,
            Ps_tip_deucode: '1',
            rotativo: rotativo,
        };
        setIsLoading(true);
        const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
        if (resultEnvioCorreo.response.substr(0, 12) == '250 2.0.0 Ok') {
            setIsLoading(false);
            toast.success('Firma enviada correctamente');
        } else {
            toast.error('Se presento un error al momento de enviar la firma, intente nuevamente');
        }
    };

    const obtenerPlanPagos = async () =>{
        const planP = { 
            pws_ident_cliente: dato.Pws_Identificacion ,
            pws_lin_cre: dato.Pws_linea_credito,
            pws_num_radic: dato.Pws_num_radic
        }
        try{
            const planPagos = await WLOCreditAPIService.getPlanPagos(planP);
            setDatosP(planPagos.payload.data.Res.Res_DetAvance[0].Avance.Avances[0].Detalle.Detalles);
            setAction(true)
            props.onDatosPChange(planPagos.payload.data.Res.Res_DetAvance[0].Avance.Avances[0].Detalle.Detalles);
        }catch(e){
            console.log(e);
        }
    }

    return (
        <div className="box" id="documentos1">
            <ClockLoader id="spinner" color={'#d72222'} loading={isLoading} size={100} />
            <ul className="title">
                <li>1.</li>
                <li>Información del crédito </li>
                <Doc />
            </ul>
            <div className="sub-box">
                <ul className="w-100">
                    <li>
                        <label>Línea de crédito:</label>
                        <p>
                            {' '}
                            {listCredit.filter((valor: any) => valor.s_codigo == Number(dato.Pws_linea_credito)).length > 0
                                ? listCredit.filter((valor: any) => valor.s_codigo == dato.Pws_linea_credito)[0]['s_nombre']
                                : 'No tiene'}
                        </p>
                    </li>
                    <li>
                        <label>Numero de solicitud:</label>
                        <p>{dato.Pws_num_solicitud}</p>
                    </li>
                    <li>
                        <label>Valor cuota inicial:</label>
                        <p>
                            <NumberFormat
                                value={parseFloat(dato.Pws_Val_cuoini)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />{' '}
                        </p>
                    </li>
                    <li>
                        <label>Monto de crédito:</label>
                        <p>
                            <NumberFormat
                                value={parseFloat(dato.Pws_Val_finan)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />{' '}
                        </p>
                    </li>
                </ul>
                <ul className="w-100">
                    <li>
                        <label>Periodicidad de crédito:</label>
                        <p>{dato.Pws_Periodic}</p>
                    </li>
                    <li>
                        <label>Plazo:</label>
                        <p>
                            {dato.Pws_Val_pla} {dato.Pws_Periodic}
                        </p>
                    </li>
                    <li>
                        <label>Valor de cuota:</label>
                        <p>
                            {' '}
                            <NumberFormat
                                value={parseFloat(dato.Pws_Val_cuo)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />{' '}
                        </p>
                    </li>
                    <li>
                    </li>
                </ul>
                <ul className="submit">
                    <button onClick={() => obtenerPlanPagos() }>Ver plan de pagos</button>
                    <button onClick={() => forwardingFirma()}>Enviar firma</button>
                    <GreenCheck />
                </ul>
            </div>
        </div>
    );
};
export const Box2 = (valorPersonal: any) => {
    const { infoCredito, dataConyuge, dataDesem } = valorPersonal.valorPersonal;
    const [persona, DatoPersona] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [realizarAccion, setUpdate2] = React.useState(false);
    const intl = useIntl();
    const [one] = React.useState(false);
    const [ciudades, setCiudades] = React.useState(cities as any);
    const [ciudadesDepartamentos, setCiudadesDepartamentos] = React.useState(cities as any);
    const [departamentos, setDepartamentos] = React.useState('' as any);
    const [revisado, setRevisado] = React.useState(true);
    const [allCities, setAllCiudades] = React.useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([] as any);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [TipoContrato, setAllTipoContrato] = useState([] as any);
    const [allOcupaciones, SetAllOcupaciones] = useState([]);
    const [modal, setModal] = useState(false);
    
    let consultaCiudad = false;

    const handleSelectChange = (event: any) => {
        console.log(event.target.value)
        DatoPersona({...persona, Pws_Estado_Civil: event.target.value})
       // persona.Pws_Estado_Civil = event.target.value; 
        if (event.target.value === "1" || event.target.value === "3") {
            setModal(!modal);
        }
    };

    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (consultaCiudad == false) {
            AdminService.getAllCiudades().then((Datos: any) => {
                if (parseInt(Datos.payload.data.ListCiudad.Datos_Ciudad[0].s_codigo) > 0) {
                    setAllCiudades(Datos.payload.data);
                    consultaCiudad = true;
                }
            });
        }
        getAlldata();
    }, [one]);
    const getAlldata = async () => {
        const estadosCvil = await AdminService.getEstadosCiviles();
        if (
            estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length > 0 &&
            estadosCvil.payload.data.listEstCivil.Datos_EstCivil[0].s_codigo > 0
        ) {
            setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil);
        } else {
            let estadocivil = [
                { s_codigo: '1', s_descripcion: 'CASADO' },
                { s_codigo: '2', s_descripcion: 'SOLTERO' },
                { s_codigo: '3', s_descripcion: 'UNION LIBRE' },
                { s_codigo: '4', s_descripcion: 'VIUDO' },
                { s_codigo: '5', s_descripcion: 'SEPARADO' },
            ];
            setEstadosCivil(estadocivil);
        }

        const tipovivienda = await AdminService.gettipovivienda();
        if (tipovivienda.payload.data.tip_viv.datos_tip_vivi.length > 0) {
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi);
        }
        const nivelEstudi = await AdminService.getNivelEstudio();
        if (nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length > 0) {
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio);
        }
        const tipocon = await AdminService.getTipoContrato();
        if (
            tipocon.payload.data.listTipoContr.Datos_TipoContrato.length > 0 &&
            parseInt(tipocon.payload.data.listTipoContr.Datos_TipoContrato[0].s_codigo) > 0
        ) {
            setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato);
        } else {
            let tipContrato = [
                { s_codigo: '1', s_descripcion: 'INDEFINIDO' },
                { s_codigo: '2', s_descripcion: 'TERMINO FIJO' },
                { s_codigo: '3', s_descripcion: 'N/A' },
                { s_codigo: '4', s_descripcion: 'OBRA O LABOR' },
                { s_codigo: '5', s_descripcion: 'TEMPORAL' },
            ];
            setAllTipoContrato(tipContrato);
        }

        const ocupaciones = await AdminService.getOcupaciones();
        if (ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length > 0) {
            SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones);
        }
    };
    useEffect(() => {
        if (allCities) {
            let citiesDepent = {
                ListCiudad: {
                    Datos_Ciudad: [] as any,
                },
            };
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2' });
            if (parseInt(citiesDepent.ListCiudad.Datos_Ciudad.length) > 0) {
                setCiudades(citiesDepent);
                setCiudadesDepartamentos(citiesDepent);
            }
        }
    }, [allCities]);
    const handleCities = (e: { target: { value: string } }) => {
        let citiesDepent = {
            ListCiudad: {
                Datos_Ciudad: [] as any,
            },
        };
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
    };
    useEffect(() => {
        const personainfo = valorPersonal.valorPersonal.person;
        const personaRequest = {
            Pws_Identificacion: personainfo.Pws_Identificacion,
            Pws_Tip_Identificacion: personainfo.Pws_Tip_Identificacion,
            Pws_Tip_Estado: personainfo.Pws_Tip_Estado, // Valor constante para confirmación de información.
            Pws_Tip_Perfil: personainfo.Pws_Tip_Perfil,
            Pws_Tip_person: personainfo.Pws_Tip_person,
            Pws_Tip_ocupa: personainfo.Pws_Tip_ocupa,
            Pws_Nombres: personainfo.Pws_Nombres,
            Pws_Apellidos1: personainfo.Pws_Apellidos1,
            Pws_Apellidos2: personainfo.Pws_Apellidos2,
            Pws_Telefono1: personainfo.Pws_Telefono1,
            Pws_Telefono2: personainfo.Pws_Telefono2,
            Pws_Correo: personainfo.Pws_Correo,
            Pws_Fec_expe: personainfo.Pws_Fec_expe ? personainfo.Pws_Fec_expe.split('-00.')[0] : '',
            Pws_Lugar_exp: personainfo.Pws_Lugar_exp,
            Pws_Fec_nacime: personainfo.Pws_Fec_nacime ? personainfo.Pws_Fec_nacime.split('-00.')[0] : '',
            Pws_Estado_Civil: personainfo.Pws_Estado_Civil,
            Pws_Direccion_res: personainfo.Pws_Direccion_res,
            Pws_Departamento: personainfo.Pws_Departamento,
            Pws_Ciudad_res: personainfo.Pws_Ciudad_res,
            Pws_Genero: personainfo.Pws_Genero,
            Pws_Estrato_per: personainfo.Pws_Estrato_per,
            Pws_Per_cargo: personainfo.Pws_Per_cargo,
            Pws_Tip_vivienda: personainfo.Pws_Tip_vivienda,
            Pws_Niv_estudio: personainfo.Pws_Niv_estudio,
            Pws_Nom_empre: personainfo.Pws_Nom_empre,
            Pws_fec_ingemp: personainfo.Pws_fec_ingemp ? personainfo.Pws_fec_ingemp.split('-00.')[0] : '',
            Pws_Fideliza: personainfo.Pws_Fideliza,
            Pws_Tip_contra: personainfo.Pws_Tip_contra,
            Pws_Ant_labo: personainfo.Pws_Ant_labo,
            Pws_Car_emp: personainfo.Pws_Car_emp,
            Pws_Nom_Jefedi: personainfo.Pws_Nom_Jefedi,
            Pws_Direc_emp: personainfo.Pws_Direc_emp,
            Pws_Ciud_emp: personainfo.Pws_Ciud_emp,
            Pws_tel_emp1: personainfo.Pws_tel_emp1,
            Pws_tel_emp2: personainfo.Pws_tel_emp2,
        };

        const infoUpdatePerson = { ...personaRequest, Pws_i_codigo: personainfo.Pws_i_codigo };
        DatoPersona(infoUpdatePerson);
        if (valorPersonal.valorPersonal.person?.Pws_Departamento) {
            try {
                if (departamentos == '') {
                    setDepartamentos(
                        _.filter(ciudadesDepartamentos.ListCiudad.Datos_Ciudad, {
                            s_codigo: valorPersonal.valorPersonal.person.Pws_Departamento,
                        })[0]?.s_nombre
                    );
                }
            } catch (error) {}
        }
    }, [valorPersonal]);
    const Actualizar_Info = async (valor: any) => {
        const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(valor);
        if (Number(resultUpdatePersona.payload.result) > 0) {
            agregarCygnus(valor);
        } else {
            toast.error('Error al Actualizar');
        }
    };
    const agregarCygnus = async (persona: any) => {
        const cygnus = await WLOCreditAPIService.grabar_cignus({
            Pws_Identificacion: persona.Pws_Identificacion,
            Pws_Tip_Identificacion: persona.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: persona.Pws_Tip_Perfil!,
        });

        if (Number(cygnus.payload.result) > 0) {
            toast('Actualizado correctamente');
            valorPersonal.valorPersonal.Busquedatotal();
        } else {
            toast.error(cygnus.payload.MensajeError);
        }
    };
    const cambiarUpdat = () => {        
        if (!update) {
            let citiesDepent = {
                ListCiudad: {
                    Datos_Ciudad: [] as any,
                },
            };
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, {
                s_nivel: '3',
                s_ubicacion: persona.Pws_Departamento,
            });
            setCiudades(citiesDepent);
            setUpdate2(true);
        }
        if (realizarAccion) {
            setUpdate2(false);
            document.getElementById('botonSubmit')?.click();
        }
        setUpdate(!update);
    };
    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {
        Actualizar_Info(values);
    };

    return (
        <div className="box">
            <Formik enableReinitialize={true} initialValues={persona} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, handleSubmit }) => {
                    const cambios = (evento: any) => {
                        var dateStart = new Date(evento.target.value);
                        var dateEnd = new Date();
                        if (dateStart.getTime() > dateEnd.getTime()) {
                            toast('La fecha de ingreso no debe ser mayor a la fecha actual');
                        } else {
                            var results = dateEnd.getFullYear() - dateStart.getFullYear();
                            let diferencia = dateEnd.getTime() - dateStart.getTime();
                            let diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24;
                            if (
                                diasDeDiferencia > 365 &&
                                dateStart.getMonth() <= dateEnd.getMonth() &&
                                dateStart.getDate() <= dateEnd.getDate()
                            ) {
                                values.Pws_Ant_labo = results;
                            } else {
                                values.Pws_Ant_labo = results - 1;
                            }
                        }
                    };
                    return (
                        <>
                            <Form onSubmit={handleSubmit} id="valorFormSuc">
                                <ul className="title">
                                    <li>2.</li>
                                    <li>Información personal </li>
                                    <Person />
                                    
                                    {
                                        dataDesem.estado != 'Desembolsado' &&
                                      
                                        <div onClick={cambiarUpdat}>
                                            <Edit />
                                        </div> 
                                    }
                                   
                                </ul>
                                <div className="sub-box">
                                    <ul className="nopadding">
                                        <li>
                                            <label>¿Quién acredita?</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Tip_person" className="nopadding form-know-you-item-input">
                                                        <option value={'N'}>{'Natural'}</option>
                                                        {/* <option value={'J'}>{'Juridico'}</option> */}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? (persona.Pws_Tip_person === 'N' ? 'Natural' : 'Juridico') : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Tipo de ocupación</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Tip_ocupa" className="nopadding form-know-you-item-input">
                                                        <option value={""}>{'Seleccione...'}</option>
                                                        {allOcupaciones &&
                                                            allOcupaciones.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {allOcupaciones &&
                                                            allOcupaciones.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Tip_ocupa)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <div className="sub-box redBox">
                                    <h3>Información del solicitante</h3>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Nombres</label>
                                            {
                                                <>
                                                    <p>{persona ? (persona.Pws_Nombres ? persona.Pws_Nombres : '') : ''}</p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Apellidos</label>
                                            {
                                                <>
                                                    <p>
                                                        {persona
                                                            ? persona.Pws_Apellidos1
                                                                ? persona.Pws_Apellidos1 + ' ' + persona.Pws_Apellidos2
                                                                : ''
                                                            : ''}{' '}
                                                    </p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Tipo de identificación</label>

                                            {
                                                <>
                                                    <p>
                                                        {' '}
                                                        {typeIdentification &&
                                                            typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map(
                                                                (item, key) => {
                                                                    if (Number(item.s_codigo) === Number(persona.Pws_Tip_Identificacion)) {
                                                                        return item.s_descripcion;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            )}
                                                    </p>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <label>Numero de Identifacion</label>

                                            {
                                                <>
                                                    <p>{persona.Pws_Identificacion}</p>
                                                </>
                                            }
                                        </li>

                                        <li>
                                            <label>Fecha de expedición</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Fec_expe'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Fec_expe'}
                                                        type={'date'}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona.Pws_Fec_expe ? persona.Pws_Fec_expe : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Lugar de expedición</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        name="Pws_Lugar_exp"
                                                        className="nopadding form-know-you-item-input"
                                                        type={'date'}
                                                        placeholder={'MM/dd/YYYY'}>
                                                        {allCities &&
                                                            _.filter(allCities.ListCiudad.Datos_Ciudad).map((item: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_codigo: persona.Pws_Lugar_exp })
                                                            .length > 0
                                                            ? _.filter(allCities.ListCiudad.Datos_Ciudad, {
                                                                  s_codigo: persona.Pws_Lugar_exp,
                                                              })[0].s_nombre
                                                            : ''}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Fecha de nacimiento</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Fec_nacime'}
                                                        name={'Pws_Fec_nacime'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'date'}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona.Pws_Fec_nacime ? persona.Pws_Fec_nacime : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Estado civil</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        name="Pws_Estado_Civil"
                                                        className="nopadding form-know-you-item-input"
                                                        onChange={handleSelectChange}>
                                                        {EstadosCivil &&
                                                            EstadosCivil.map((item: any, key: any) => {
                                                                return (
                                                                    <option value={item.s_codigo} key={key}>
                                                                        {item.s_descripcion}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {EstadosCivil &&
                                                            EstadosCivil.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Estado_Civil)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Dirección residencia</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Direccion_res'}
                                                        name={'Pws_Direccion_res'}
                                                        className="nopadding form-know-you-item-input"
                                                        //    onFocus={()=>{
                                                        //     setRequest({
                                                        //         ...request,
                                                        //         valoresDireccion: {identificacion:persona.Pws_Identificacion,
                                                        //             tipoindentificacion:persona.Pws_Tip_Identificacion}
                                                        //     })
                                                        //     valorPersonal.valorPersonal.setPopup("open")
                                                        // }}
                                                        // readonly='readonly'
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Direccion_res : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        {/* <li>
                        <label>Departamento</label>
                        {
                            update?<>
                            <Field as="select"
                               name="Pws_Departamento"
                                onClick={handleCities}
                                className="nopadding form-know-you-item-input"
                                
                        >   
                            {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>

                             
                            </>:<> 
                            <p>{departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                if(item.s_codigo===persona.Pws_Departamento){
                                    return (item.s_nombre)

                                }else{
                                    return ("")
                                }
                            })}</p>
                            </>
                        }
                    </li> */}
                                        <li>
                                            <label>Ciudad</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" name="Pws_Ciudad_res" className="nopadding form-know-you-item-input">
                                                        {ciudades &&
                                                            ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_nombre}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {allCities.ListCiudad.Datos_Ciudad &&
                                                            allCities.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Ciudad_res)) {
                                                                    return item.s_nombre;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono móvil 1</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Telefono1'}
                                                        type={'number'}
                                                        name={'Pws_Telefono1'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Telefono1 : ''} </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Teléfono móvil 2</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Telefono2'}
                                                        type={'number'}
                                                        name={'Pws_Telefono2'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Telefono2 : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Email</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Correo'}
                                                        name={'Pws_Correo'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Correo : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Género</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Genero">
                                                        <option>{intl.formatMessage({ id: 'placeholder_dropdown' })}</option>
                                                        <option value={'F'}>{'Femenino'}</option>
                                                        <option value={'M'}>{'Masculino'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? (persona.Pws_Genero === 'F' ? 'Femenino' : 'Masculino') : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Estrato social</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Estrato_per">
                                                        {estractos &&
                                                            estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map(
                                                                (item, key) => {
                                                                    return <option value={item.i_codigo}>{item.c_descripcion}</option>;
                                                                }
                                                            )}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {' '}
                                                        {estractos &&
                                                            estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map(
                                                                (item, key: number) => {
                                                                    if (Number(item.i_codigo) === Number(persona.Pws_Estrato_per)) {
                                                                        return item.c_descripcion;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }
                                                            )}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>N° de personas a cargo</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Per_cargo">
                                                        <option value={'0'}>{'0'}</option>
                                                        <option value={'1'}>{'1'}</option>
                                                        <option value={'2'}>{'2'}</option>
                                                        <option value={'3'}>{'3'}</option>
                                                        <option value={'4'}>{'4'}</option>
                                                        <option value={'5'}>{'5'}</option>
                                                        <option value={'6'}>{'6'}</option>
                                                        <option value={'7'}>{'7'}</option>
                                                        <option value={'8'}>{'8'}</option>
                                                        <option value={'9'}>{'9'}</option>
                                                        <option value={'10'}>{'10'}</option>
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Per_cargo : ''}</p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Tipo de vivienda </label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Tip_vivienda">
                                                        {tipovivi &&
                                                            tipovivi.map((item: any, key) => {
                                                                return <option value={item.i_codigo}>{item.c_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {tipovivi &&
                                                            tipovivi.map((item: any, key: number) => {
                                                                if (Number(item.i_codigo) === Number(persona.Pws_Tip_vivienda)) {
                                                                    return item.c_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Nivel de estudios </label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        as="select"
                                                        className="nopadding form-know-you-item-input"
                                                        name="Pws_Niv_estudio">
                                                        {NivelEsti &&
                                                            NivelEsti.map((item: any, key) => {
                                                                return <option value={item.s_codigo}>{item.s_descripcion}</option>;
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {' '}
                                                        {NivelEsti &&
                                                            NivelEsti.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Niv_estudio)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <FormSpouseCodeudor dataConyuge={dataConyuge} values={valorPersonal.valorPersonal.person} show={modal} onHide={toggle} solicitud={infoCredito.Pws_num_solicitud} />
                                    </ul>
                                </div>
                                <div className="sub-box redBox">
                                    <h3>Actividad del solicitante</h3>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Actividad del solicitante</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Nom_empre'}
                                                        name={'Pws_Nom_empre'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Nom_empre : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Fecha de ingreso</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_fec_ingemp'}
                                                        name={'Pws_fec_ingemp'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'date'}
                                                        onBlur={cambios}
                                                        placeholder={'MM/dd/YYYY'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {persona.Pws_fec_ingemp
                                                            ? new Date(persona.Pws_fec_ingemp).toLocaleDateString()
                                                            : ''}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Tipo de contrato</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Tip_contra">
                                                        {TipoContrato &&
                                                            TipoContrato.map((item: any, key: any) => {
                                                                return (
                                                                    <option key={key} value={item.s_codigo}>
                                                                        {item.s_descripcion}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {TipoContrato &&
                                                            TipoContrato.map((item: any, key: number) => {
                                                                if (Number(item.s_codigo) === Number(persona.Pws_Tip_contra)) {
                                                                    return item.s_descripcion;
                                                                } else {
                                                                    return '';
                                                                }
                                                            })}{' '}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Antigüedad laboral (años)</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Ant_labo'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'number'}
                                                        name={'Pws_Ant_labo'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Ant_labo : ''} años </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Cargo que desempeña</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Car_emp'}
                                                        name={'Pws_Car_emp'}
                                                        className="nopadding form-know-you-item-input"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Car_emp : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Jefe directo</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Nom_Jefedi'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Nom_Jefedi'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Nom_Jefedi : ''}</p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="nopadding">
                                        <li>
                                            <label>Dirección de la empresa</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_Direc_emp'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_Direc_emp'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_Direc_emp : ''} </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Ciudad</label>
                                            {update ? (
                                                <>
                                                    <Field as="select" className="nopadding form-know-you-item-input" name="Pws_Ciud_emp">
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }) &&
                                                            _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map(
                                                                (item: any, key: number) => {
                                                                    return (
                                                                        <option key={key} value={item.s_codigo}>
                                                                            {item.s_nombre}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map(
                                                            (item: any, key: number) => {
                                                                if (item.s_codigo === persona.Pws_Ciud_emp) {
                                                                    return item.s_nombre;
                                                                } else {
                                                                    return '';
                                                                }
                                                            }
                                                        )}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono 1</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_tel_emp1'}
                                                        className="nopadding form-know-you-item-input"
                                                        type={'number'}
                                                        name={'Pws_tel_emp1'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_tel_emp1 : ''}</p>
                                                </>
                                            )}
                                        </li>
                                        <li>
                                            <label>Teléfono 2</label>
                                            {update ? (
                                                <>
                                                    <Field
                                                        id={'Pws_tel_emp2'}
                                                        type={'number'}
                                                        className="nopadding form-know-you-item-input"
                                                        name={'Pws_tel_emp2'}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p>{persona ? persona.Pws_tel_emp2 : ''} </p>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <button type="submit" id="botonSubmit" style={{ display: 'none' }}></button>
                                <div className="submit">
                                    {valorPersonal.valorPersonal.userPermisos ? (
                                        <>
                                            {revisado ? (
                                                <>
                                                    <button
                                                        type="button"
                                                        style={{ marginTop: '10px' }}
                                                        onClick={() => {
                                                            setRevisado(false);
                                                            valorPersonal.valorPersonal.changeDatoList(1);
                                                        }}>
                                                        Revisado
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    {' '}
                                                    <GreenCheck />{' '}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};
export const Box3 = (valorFinancie: any) => {
    const { dataDesem } = valorFinancie.valorFinancie;
    const [financier, Valorfinan] = React.useState({} as any);
    const [valorperps, valorPEps] = React.useState({} as any);
    const [datosInicial, valoresIncial] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [realizarAccion, setUpdate2] = React.useState(false);
    const [revisado, setRevisado] = React.useState(true);
    const [numeroSolicitud, setnumeroSolicitud] = React.useState(true);
    useEffect(() => {
        if (valorFinancie.valorFinancie.datopeps) valorPEps(valorFinancie.valorFinancie.datopeps);
        if (valorFinancie.valorFinancie.valorinic) Valorfinan(valorFinancie.valorFinancie.valorinic);
        const operFinancieras = {
            s_num_solicitud: valorFinancie.valorFinancie.datosIngr[0],
            s_identificacion: valorFinancie.valorFinancie.datosIngr[1],
            s_tipo_identificacion: valorFinancie.valorFinancie.datosIngr[2],
            s_ingreso_principal: valorFinancie.valorFinancie.valorinic.Pws_Ingreso_Principal,
            s_otros_ingresos: valorFinancie.valorFinancie.valorinic.Pws_otros_ingresos,
            s_otros_egresos: valorFinancie.valorFinancie.valorinic.Pws_Otros_egresos,
            s_arriendo: valorFinancie.valorFinancie.valorinic.Pws_Arriendo,
            s_concep_otr_ingre: valorFinancie.valorFinancie.valorinic.Pws_Concep_otr_ingre,
            s_declarante_ren: valorFinancie.valorFinancie.valorinic.Pws_Declarante_ren == '1' ? true : false,
            s_moneda_ext: valorFinancie.valorFinancie.valorinic.Pws_Moneda_ext == '1' ? true : false,
            s_monext_oper: valorFinancie.valorFinancie.valorinic.Pws_Monext_oper,
            s_tip_monext: valorFinancie.valorFinancie.valorinic.Pws_Tip_monext,
            s_cuent_ext: valorFinancie.valorFinancie.valorinic.Pws_Cuent_ext == '1' ? true : false,
            s_cuen_extban: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extban,
            s_cuen_extnum: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extnum,
            s_cuen_extpais: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extpais,
            s_cuen_extciudad: valorFinancie.valorFinancie.valorinic.Pws_Cuen_extciudad,
            s_pep_recpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_recpublic == '1' ? true : false,
            s_pep_poderpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_poderpublic == '1' ? true : false,
            s_pep_reconpublic: valorFinancie.valorFinancie.datopeps.Pws_Pep_Reconpublic == '1' ? true : false,
            s_pep_pubexpue: valorFinancie.valorFinancie.datopeps.Pws_Pep_pubexpue == '1' ? true : false,
            s_pep_seggraconsa: valorFinancie.valorFinancie.datopeps.Pws_Pep_seggraconsa == '1' ? true : false,
            s_pep_nompepseg: valorFinancie.valorFinancie.datopeps.Pws_Pep_nompepseg,
            s_pep_paren: valorFinancie.valorFinancie.datopeps.Pws_Pep_paren,
            s_pep_identif: valorFinancie.valorFinancie.datopeps.Pws_Pep_Identif,
        };
        valoresIncial(operFinancieras);
    }, [valorFinancie]);

    const cambiarUpdat = () => {
        if (!update) {
            setUpdate2(true);
        }
        if (realizarAccion) {
            setUpdate2(false);
            document.getElementById('botonSubmit2')?.click();
        }
        setUpdate(!update);
    };

    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {
        setnumeroSolicitud(values.s_num_solicitud);
        const operFinancieras = {
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion,
            s_tipo_identificacion: values.s_tipo_identificacion,
            s_ingreso_principal: values.s_ingreso_principal,
            s_otros_ingresos: values.s_otros_ingresos,
            s_otros_egresos: values.s_otros_egresos,
            s_arriendo: values.s_arriendo,
            s_concep_otr_ingre: values.s_concep_otr_ingre,
            s_declarante_ren: values.s_declarante_ren ? '1' : '0',
            s_moneda_ext: values.s_moneda_ext ? '1' : '0',
            s_monext_oper: values.s_monext_oper,
            s_tip_monext: values.s_tip_monext,
            s_cuent_ext: values.s_cuent_ext ? '1' : '0',
            s_cuen_extban: values.s_cuen_extban,
            s_cuen_extnum: values.s_cuen_extnum,
            s_cuen_extpais: values.s_cuen_extpais,
            s_cuen_extciudad: values.s_cuen_extciudad,
        };
        const result2 = await WLOCreditAPIService.addFinancialInformation(operFinancieras);
        const peps = {
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion,
            s_tipo_identificacion: values.s_tipo_identificacion,
            s_pep_recpublic: values.s_pep_recpublic ? '1' : '0',
            s_pep_poderpublic: values.s_pep_poderpublic ? '1' : '0',
            s_pep_reconpublic: values.s_pep_reconpublic ? '1' : '0',
            s_pep_pubexpue: values.s_pep_pubexpue ? '1' : '0',
            s_pep_seggraconsa: values.s_pep_seggraconsa ? '1' : '0',
            s_pep_nompepseg: values.s_pep_nompepseg,
            s_pep_paren: values.s_pep_paren,
            s_pep_identif: values.s_pep_identif,
        };
        const result = await WLOCreditAPIService.addPeps(peps);
        const cygnus = await WLOCreditAPIService.grabar_cignus({
            Pws_Identificacion: values.s_identificacion,
            Pws_Tip_Identificacion: values.s_tipo_identificacion,
            Pws_Tip_Perfil: '2',
        });
        valorFinancie.valorFinancie.Busquedatotal();
        toast('Actualizado correctamente');
    };
    return (
        <div className="box">
            <Formik enableReinitialize={true} initialValues={datosInicial} onSubmit={onSubmit}>
                {({ isSubmitting, values, errors, handleSubmit }) => (
                    <>
                        <Form>
                            <ul className="title">
                                <li>3.</li>
                                <li>Información financiera </li>
                                <Doc />
                                {
                                    dataDesem.estado != 'Desembolsado' &&
                                    
                                    <div onClick={cambiarUpdat}>
                                        <Edit />
                                    </div> 
                                }                               
                            </ul>
                            <div className="sub-box redBox">
                                <h3>Ingresos del solicitante</h3>
                                <ul className="nopadding">
                                    <li>
                                        <label>Ingreso principal</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_ingreso_principal"
                                                    className="nopadding"
                                                    name="s_ingreso_principal"
                                                    placeholder={'prueba'}
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_ingreso_principal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Otros ingresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_otros_ingresos"
                                                    className="nopadding"
                                                    name="s_otros_ingresos"
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_otros_ingresos}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Valor egresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_otros_egresos"
                                                    className="nopadding"
                                                    name="s_otros_egresos"
                                                    component={ParseNumberThousand}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <NumberFormat
                                                        value={values.s_otros_egresos}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />{' '}
                                                </p>
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        <label>Concepto de otros ingresos</label>
                                        {update ? (
                                            <>
                                                <Field
                                                    id="s_concep_otr_ingre"
                                                    className="nopadding form-know-you-item-input"
                                                    name="s_concep_otr_ingre"
                                                    type="text"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p>{values.s_concep_otr_ingre}</p>
                                            </>
                                        )}
                                    </li>
                                </ul>
                                <h3>Propiedades del solicitante</h3>
                                <ul className="nopadding">
                                    <li>
                                        <label>Declarante de renta</label>
                                        {update ? (
                                            <>
                                                <div className="form-know-you-item-medium display-flex">
                                                    No
                                                    <Field
                                                        id={'s_declarante_ren'}
                                                        className="nopadding"
                                                        name="s_declarante_ren"
                                                        component={Switch}
                                                        type="checkbox"
                                                    />
                                                    Si
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <FUllPill
                                                    check={values.s_declarante_ren}
                                                    disabled={true}
                                                    id={'s_declarante_ren'}
                                                    name={'s_declarante_ren'}
                                                />
                                            </>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="sub-box redBox">
                                <h3> Operaciones financieras </h3>
                                <div className="pillInfo">
                                    <p>¿Realizas operaciones en moneda extranjera?</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-small display-flex">
                                                No
                                                <Field id={'s_moneda_ext'} name="s_moneda_ext" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_moneda_ext}
                                                disabled={true}
                                                id={'s_moneda_ext'}
                                                name={'s_moneda_ext'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>Posees cuentas en moneda extranjera</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_cuent_ext'} name="s_cuent_ext" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill check={values.s_cuent_ext} disabled={true} id={'s_cuent_ext'} name={'s_cuent_ext'} />
                                        </>
                                    )}
                                </div>
                                <h3>{`Información PEP. (Personas expuestas Políticamente)`}</h3>
                                <div className="pillInfo">
                                    <p>¿Por tu cargo manejas recursos públicos? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_pep_recpublic'} name="s_pep_recpublic" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_recpublic}
                                                disabled={true}
                                                id={'s_pep_recpublic'}
                                                name={'s_pep_recpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Por tu cargo o actividad ejerces algún grado de poder público? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_poderpublic'}
                                                    name="s_pep_poderpublic"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_poderpublic}
                                                disabled={true}
                                                id={'s_pep_poderpublic'}
                                                name={'s_pep_poderpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Gozas de reconocimiento público? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_reconpublic'}
                                                    name="s_pep_reconpublic"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_reconpublic}
                                                disabled={true}
                                                id={'s_pep_reconpublic'}
                                                name={'s_pep_reconpublic'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>¿Eres familiar de alguna persona políticamente expuesta? </p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field id={'s_pep_pubexpue'} name="s_pep_pubexpue" component={Switch} type="checkbox" />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_pubexpue}
                                                disabled={true}
                                                id={'s_pep_pubexpue'}
                                                name={'s_pep_pubexpue'}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="pillInfo">
                                    <p>{`¿Tienes 2° de afinidad y 2° de consanguinidad (Padres, hermanos, esposa, primos, suegros, cuñados o concuñados) con PEP?`}</p>
                                    {update ? (
                                        <>
                                            <div className="form-know-you-item-medium display-flex">
                                                No
                                                <Field
                                                    id={'s_pep_seggraconsa'}
                                                    name="s_pep_seggraconsa"
                                                    component={Switch}
                                                    type="checkbox"
                                                />
                                                Si
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FUllPill
                                                check={values.s_pep_seggraconsa}
                                                disabled={true}
                                                id={'s_pep_seggraconsa'}
                                                name={'s_pep_seggraconsa'}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <button type="submit" id="botonSubmit2" style={{ display: 'none' }}></button>
                        </Form>
                    </>
                )}
            </Formik>
            <div className="submit">
                {valorFinancie.valorFinancie.userPermisos ? (
                    <>
                        {revisado ? (
                            <>
                                <button
                                    type="button"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setRevisado(false);
                                        valorFinancie.valorFinancie.changeDatoList(2);
                                    }}>
                                    Revisado
                                </button>
                            </>
                        ) : (
                            <>
                                {' '}
                                <GreenCheck />{' '}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export const Box4 = (valorDocuments: any) => {
    const { dataDesem } = valorDocuments.valorDocuments;
    const [update, setUpdate] = React.useState(false);
    const [UpdateDocumentosLegales, setUpdateDocumentosLegales] = React.useState(false);
    const [alldocuments, setDocuments] = React.useState([
        {
            Pws_Doc_Tipo: 1,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 2,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 3,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 4,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 5,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
    ]);
    const { user, setUser } = useLoginContext();
    useEffect(() => {
        if (valorDocuments.valorDocuments.alldocuments) {
            if (valorDocuments.valorDocuments.alldocuments.length > 0) {
                let valordocumentos = [
                    {
                        Pws_Doc_Tipo: 1,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    {
                        Pws_Doc_Tipo: 2,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    {
                        Pws_Doc_Tipo: 3,
                        Pws_Doc_nombre: '',
                        Pws_Clave_doc: '',
                        Pws_Doc_estado: '',
                        Pws_Doc_ruta: '',
                    },
                    // {
                    //     Pws_Doc_Tipo: 4,
                    //     Pws_Doc_nombre: '',
                    //     Pws_Clave_doc: '',
                    //     Pws_Doc_estado: '',
                    //     Pws_Doc_ruta: '',
                    // },
                    // {
                    //     Pws_Doc_Tipo: 5,
                    //     Pws_Doc_nombre: '',
                    //     Pws_Clave_doc: '',
                    //     Pws_Doc_estado: '',
                    //     Pws_Doc_ruta: '',
                    // },
                ] as any;
                valorDocuments.valorDocuments.alldocuments.forEach((element: any) => {
                    if (element.Pws_Doc_estado == '1') valordocumentos[Number(element.Pws_Doc_Tipo) - 1] = element;
                });
                setDocuments(valordocumentos);
            }
        }
    }, [valorDocuments]);
    const [revisado, setRevisado] = React.useState(true);
    const cambiarUpdat = () => {
        if (update) {
            valorDocuments.valorDocuments.Busquedatotal();
        }
        setUpdate(!update);
    };
    const cambiarUpdateDocuLega = () => {
        if (UpdateDocumentosLegales) {
            valorDocuments.valorDocuments.Busquedatotal();
        }
        setUpdateDocumentosLegales(!UpdateDocumentosLegales);
    };

    const handleFileInputChange = (e: any) => {
        const target = e.target;
        if (target.files && target.files[0]) {
            const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                toast.error('Seleccione un archivo de tamaño máximo 5mb.');
                target.value = '';
            }
        }
        let file: any = '';
        file = e.target.files[0];
        getBase64(file)
            .then(async (result) => {
                const document = result as string;
                const documentRequest = {
                    Pws_num_solicitud: valorDocuments.valorDocuments.valor[0],
                    Pws_Identificacion: valorDocuments.valorDocuments.valor[1],
                    Pws_Tip_Identificacion: valorDocuments.valorDocuments.valor[2],
                    Pws_Doc_Tipo: target.dataset.id,
                    Pws_Doc_nombre: file.name,
                    Pws_Doc_estado: '1',
                    Pws_Doc_ruta: '1',
                    Pws_Clave_doc: document,
                };

                (async () => {
                    const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                    if (Number(solicitud.payload.result) > 0) {
                        toast.success('Subido el Nuevo Archivo');
                    }
                })();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve) => {
            let baseURL: string | ArrayBuffer | null = '';
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    return (
        <div className="box">
            <ul className="title">
                <li>4.</li>
                <li>Documentos de la solicitud </li>
                <File />
                {
                    dataDesem.estado != 'Desembolsado' &&
                    
                    <div onClick={cambiarUpdat}>
                        <Edit />
                    </div> 
                }     
            </ul>
            <div className="documents">
                {alldocuments.map((valorDocumentss: any, index) => {
                    let tipo = '';
                    let documentss = '';
                    let pasar = true;
                    if (Number(valorDocumentss.Pws_Doc_Tipo) == 1) {
                        tipo = 'Copia de Cedula';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 2) {
                        tipo = 'Recibo de Servicio publico';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 3) {
                        tipo = 'Soportes de Ingresos y/o Garantias';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    // } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 5) {
                    //     tipo = 'Otros';
                    //     documentss = valorDocumentss.Pws_Doc_nombre;
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 12) {
                        tipo = 'Documento firmado';
                        documentss = valorDocumentss.Pws_Doc_nombre;
                    } 
                    // else if (Number(valorDocumentss.Pws_Doc_Tipo) == 4) {
                    //     tipo = 'Sagrilaft';
                    //     documentss = valorDocumentss.Pws_Doc_nombre;
                    //     if (Number(user.idProfile) == 1) {
                    //     } else {
                    //         pasar = false;
                    //     }
                    // }
                    const download = () => {
                        const link = document.createElement('a');
                        link.href = valorDocumentss.Pws_Clave_doc;
                        link.setAttribute('download', documentss);
                        link.setAttribute('target', '_blank');
                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();
                        link.remove();
                    };
                    if (pasar) {
                        return (
                            <>
                                <div
                                    key={index}
                                    className={update ? documentss ? 'redBox check' : 'redBox': documentss ? 'redBox check' : 'redBox'}
                                    onClick={(eve: any) => {
                                        if (update) {
                                            eve.currentTarget.querySelector('input')?.click();
                                        } else {
                                            if (documentss) {
                                                download();
                                            }
                                        }
                                    }}>
                                    {update ? (
                                        <>
                                            <input
                                                data-id={valorDocumentss.Pws_Doc_Tipo}
                                                type="file"
                                                name="file-input"
                                                id="file-input"
                                                className="file-input__input"
                                                accept="application/pdf, image/png, image/jpg, image/jpeg"
                                                onChange={handleFileInputChange}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {update ? 
                                    documentss ? 
                                    (
                                        <>
                                            <Documentosred />
                                        </>
                                    ) : (
                                        <>
                                            <Documentos />
                                        </>
                                    ) : documentss ? (
                                        <>
                                            <Documentosred />
                                        </>
                                    ) :
                                    (
                                        <>
                                            <Documentos />
                                        </>
                                    )
                                    }
                                    <p>
                                        {tipo}
                                        <br />
                                        {update ? documentss ?  valorDocumentss.Pws_Doc_nombre : "(Upload)" : ""}
                                    </p>
                                </div>
                            </>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </div>
            <div className="submit">
                {valorDocuments.valorDocuments.userPermisos ? (
                    <>
                        {revisado ? (
                            <>
                                <button
                                    type="button"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setRevisado(false);
                                        valorDocuments.valorDocuments.changeDatoList(3);
                                    }}>
                                    Revisado
                                </button>
                            </>
                        ) : (
                            <>
                                {' '}
                                <GreenCheck />{' '}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export const Box5 = (valorReferencias: any) => {
    const {  dataDesem } = valorReferencias.valorReferencias;
    const [referencias, valorRefere] = React.useState([]);
    const [valuesInicial, valorInicial] = React.useState({});
    const [revisado, setRevisado] = React.useState(true);
    useEffect(() => {
        valorRefere(valorReferencias.valorReferencias.datosrefe);
        valorInicial(valorReferencias.valorReferencias.values);
    }, [valorReferencias]);
    const Busquedatotals = () => {
        valorReferencias.valorReferencias.Busquedatotal();
    };
    const onSubmit = async (values: any, formikBag: { setSubmitting: any }) => {        
        const references: any = await WLOCreditAPIService.addReferences({
            s_num_solicitud: values.s_num_solicitud,
            s_identificacion: values.s_identificacion!,
            s_tipo_identificacion: values.s_tipo_identificacion!,
            s_ref_tipo: values.s_ref_tipo!,
            s_ref_parentes: values.s_ref_parentes!,
            s_ref_nomcomple: values.s_ref_nomcomple!,
            s_ref_telfij: values.s_ref_telfij!,
            s_ref_telcel: values.s_ref_telcel!,
        });
        if (references.payload.result === '1') {
            Busquedatotals();
            toast.success('Se agregado la referencia de manera exitosa');
        }
    };
    return (
        <Formik enableReinitialize={true} initialValues={valuesInicial} onSubmit={onSubmit}>
            {({ isSubmitting, values, errors, handleSubmit }) => (
                <>
                    <Form>
                        <div className="box">
                            <ul className="title">
                                <li>5.</li>
                                <li>Referencias </li>
                            </ul>
                            <div className="table">
                                <ReferenceTable
                                    references={{
                                        refer: referencias,
                                        Busquedatotals,
                                        values: valorReferencias.valorReferencias.datosValorRefer,
                                        dataDesem
                                    }}
                                />
                            </div>

                            <div className="submit">
                                {valorReferencias.valorReferencias.userPermisos ? (
                                    <>
                                        {revisado ? (
                                            <>
                                                <button
                                                    type="button"
                                                    style={{ marginTop: '10px' }}
                                                    onClick={() => {
                                                        setRevisado(false);
                                                        valorReferencias.valorReferencias.changeDatoList(4);
                                                    }}>
                                                    Revisado
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {' '}
                                                <GreenCheck />{' '}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <button type="submit" style={{ display: 'none' }} id="boton_referencias"></button>
                    </Form>
                </>
            )}
        </Formik>
    );
};
export const Box6 = (propetarios: any) => {
    const { dataDesem } = propetarios.propetarios;
    const [propetario, agregapro] = React.useState([] as any);
    const [revisado, setRevisado] = React.useState(true);
    useEffect(() => {
        agregapro(propetarios.propetarios.valores);
    }, [propetarios]);
    return (
        <div className="box">
            <ul className="title">
                <li>6.</li>
                <li>Información codeudor propietario </li>
                <Person />
            </ul>
            <div className="table">
                <CodeudorTable propetarios={{ dataDesem, valor: propetario, type: 'prop', arreglo: propetarios.propetarios.arreglos }} />
            </div>

            <div className="submit">
                {propetarios.propetarios.userPermisos ? (
                    <>
                        {revisado ? (
                            <>
                                <button
                                    type="button"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setRevisado(false);
                                        propetarios.propetarios.changeDatoList(5);
                                    }}>
                                    Revisado
                                </button>
                            </>
                        ) : (
                            <>
                                {' '}
                                <GreenCheck />{' '}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export const Box7 = (requeridos: any) => {
    const { dataDesem } = requeridos.requeridos;
    const [requerido, agregapro] = React.useState([] as any);
    const [revisado, setRevisado] = React.useState(true);
    useEffect(() => {
        agregapro(requeridos.requeridos.valores);
    }, [requeridos]);
    return (
        <div className="box">
            <ul className="title">
                <li>7.</li>
                <li>Información codeudor requerido </li>
                <Person />
            </ul>
            <div className="table">
                <CodeudorTable propetarios={{ valor: requerido, type: 'req', arreglo: requeridos.requeridos.arreglos, dataDesem:dataDesem }} />
            </div>
            <div className="submit">
                {requeridos.requeridos.userPermisos ? (
                    <>
                        {revisado ? (
                            <>
                                <button
                                    type="button"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setRevisado(false);
                                        requeridos.requeridos.changeDatoList(6);
                                    }}>
                                    Revisado
                                </button>
                            </>
                        ) : (
                            <>
                                {' '}
                                <GreenCheck />{' '}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export const Box8 = (dato: any) => {
    const [revisado, setRevisado] = React.useState(true);
    const { user, setUser } = useLoginContext();
    const [hideEstado, sethideEstado] = React.useState(false);
    const [listaEstado, setlistaEstado] = React.useState([] as any);
    const [consulta, setconsulta] = React.useState(true);
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    const [estado, setestado] = React.useState([] as any);
    const [estadocredito, setestadocredito] = React.useState([] as any);
    const [CodigoCredito, setCodigoCredito] = React.useState([] as any);
    const [cupoConsulta, setCupoconsulta] = React.useState('');
    let sagrilaft = dato.dato.infoRetorno.sagrilaft;
    const navigate = useNavigate();
    useEffect(() => {
        setdetalleSol(dato.dato.infoCredito);
        setCupoconsulta(dato.dato.Cupoconsulta);
        if (consulta == true) {
            consultaEstados();
            //setCupoconsulta(Cupoconsulta)
        }
    });

    const cambioEstado = () => {
        sethideEstado(true);
    };
    //Consulta estados
    const consultaEstados = async () => {
        /* const consultaCupo = await WLOCreditAPIService.consultaCupo({
            Pws_Solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_num_Tip_Ident: detalleSol.Pws_Tip_Identificacion,
        })
        const cupo = consultaCupo.payload.data.Cupo
        setCupoconsulta(cupo) */

        var i_codigo = '';
        var lista = await AdminService.getListaEstado(i_codigo);
        var list = [{}];
        try {
            setlistaEstado(lista.payload.data.listEstado.Datos_estados);
            const listgadoLineas = lista.payload.data.listEstado.Datos_estados;
            for (let index = 0; index < listgadoLineas.length; index++) {
                if (listgadoLineas[index].s_codigo == dato.dato.infoCredito.Pws_Tip_Estado) {
                    setestadocredito(listgadoLineas[index].s_descripcion);
                    setCodigoCredito(listgadoLineas[index].s_codigo);
                }
            }
            setconsulta(false);
        } catch (error) {
            setlistaEstado(list);
        }
        if (listaEstado == undefined) {
            setlistaEstado(list);
        }
    };

    const closeEstado = () => {
        sethideEstado(false);
    };
    const codigoEstado = async (codigo: any) => {};
    const codigoEstados = async (values: any) => {
        setestado(values.target.value);
    };

    const initialValues = {
        Pws_Tip_Identificacion: '',
        Pws_Identificacion: '',
        Pws_Correo: '',
        Pws_Telefono: '',
    };

    return (
        <div className="box">
            <ul className="title">
                <li>8.</li>
                <li>Información de verificación</li>
                <RedCheck />
            </ul>
            <div className="sub-box">
                <ul>
                    <div className="mapping">
                        <div className="check">
                            {
                                sagrilaft ?
                                <>
                                    <Redx />
                                    <p>Riesgo</p>
                                </>
                                :
                                <>
                                    <GreenCheckBig />
                                    <p>Aprobado</p>
                                </>
                            }
                        
                            {dato.dato.infoRetorno.listaRestrictiva ? (
                                <></>
                            ) : (
                                <>
                                    <h6>Listas restrictivas</h6>
                                </>
                            )}
                            {dato.dato.infoRetorno.sagrilaft ? (
                                <></>
                            ) : (
                                <>
                                    <h6>Sagrilaft</h6>
                                </>
                            )}
                        </div>
                        <div className="score">
                            <Rating valor={dato.dato.infoRetorno.score} />
                        </div>
                    </div>
                </ul>
                <div className="submit">
                    <div className="container">
                        <div className="row">
                            {/* <div className='col-1'>     
                                    <button onClick={()=>{cambioEstado()}}>Cambiar estado</button>
                                    
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="submit">
                    {dato.dato.userPermisos ? (
                        <>
                            {revisado ? (
                                <>
                                    <button
                                        type="button"
                                        style={{ marginTop: '10px' }}
                                        onClick={() => {
                                            setRevisado(false);
                                            dato.dato.changeDatoList(7);
                                        }}>
                                        Revisado
                                    </button>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <GreenCheck />{' '}
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={`${hideEstado}divEstado`}>
                    <div className="sub-box redBox">
                        <div className="container">
                            <div className="row">
                                <div className="col-6 textcenter">
                                    <p>Estados</p>
                                </div>
                                <div className="col-4">
                                    {listaEstado.lenght > 1 ? (
                                        <>
                                            <p>{listaEstado[parseInt(detalleSol.Pws_Tip_Estado) - 1].s_descripcion}</p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="col-2">
                                    <button
                                        onClick={() => {
                                            closeEstado();
                                        }}>
                                        close
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Formik initialValues={initialValues} onSubmit={codigoEstado}>
                                        {listaEstado.length > 1 ? (
                                            <>
                                                <Field as="select" name={'option'} onBlur={codigoEstados}>
                                                    {listaEstado.map((item: any, key: number) => {
                                                        return (
                                                            <option key={key} id={item.s_codigo} value={item.s_codigo}>
                                                                {item.s_descripcion}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-6">
                                    <button onClick={() => cambioEstado()} className="cambiarEstado">
                                        Cambiar estado
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Box10 = (valor: any) => {
    const [cupoConsulta, setCupoconsulta] = React.useState('');
    const [valorAvance, setValorAvance] = React.useState('');
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    const [hideEstado, sethideEstado] = React.useState(false);
    const [GeneracionLista, setGeneracionLista] = React.useState(false);
    const [Editable, setEditable] = React.useState(false);
    const [Editable1, setEditable1] = React.useState(false);
    const [Titulo, setTitulo] = React.useState('Cupo maximo calculado:');
    useEffect(() => {
        setdetalleSol(valor.valor.infoCredito);
        consultaCupo(valor.valor.Cupoconsulta);
        if (
            valor.valor.Cupoconsulta < valor.valor.valorAFinanciar &&
            valor.valor.infoRetorno.estado == 'Pre-Aprobado' &&
            valor.valor.infoRetorno.estado == 'Desembolsado' &&
            valor.valor.infoRetorno.estado == 'Generacion de Documentos' &&
            valor.valor.infoRetorno.estado == 'Salida Vehículo'
        ) {
            setValorAvance(valor.valor.Cupoconsulta);
        } else {
            setValorAvance(valor.valor.valorAFinanciar);
        }
    });

    const consultaCupo = async (valor: any) => {
        const cuotaRequest = {
            pws_ident_cliente: detalleSol.Pws_Identificacion,
            pws_lin_cre: detalleSol.Pws_linea_credito,
        };
        const cupoConsulta = await WLOCreditAPIService.consultaCupoAsignado(cuotaRequest);
        const cupoConsultaResponse = cupoConsulta.payload.data;
        if (
            cupoConsultaResponse &&
            cupoConsultaResponse.Res_det_sald &&
            cupoConsultaResponse.Res_det_sald.Res_Det_Saldo.length > 0 &&
            cupoConsultaResponse.Res_det_sald != null &&
            cupoConsultaResponse.R_s_cod === '1'
        ) {
            const cupoConsultaValue = cupoConsultaResponse.Res_det_sald.Res_Det_Saldo[0].R_s_cupo_disp;
            setCupoconsulta(cupoConsultaValue);
            setTitulo('Cupo asignado:');
            setEditable(false);
            setEditable1(false);
        } else {
            if (Math.sign(parseInt(valor)) <= 0) {
                setCupoconsulta('0');
                setEditable(true);
            } else {
                setCupoconsulta(valor);
                setEditable1(true);
            }
        }
    };
    const cambiarAprobado = async () => {
        const valores = await WLOCreditAPIService.estadocredito({
            pws_identificacion: valor.valor.valor[1],
            pws_tip_identificacion: valor.valor.valor[2],
            pws_num_solicitud: valor.valor.valor[0],
            pws_estado: '1',
        });
        if (Number(valores.payload.message) >= 1) {
            toast.success('Guardado Exitosamente');
        }
    };

    const cambioEstado = () => {
        sethideEstado(true);
    };

    const closeEstado = () => {
        sethideEstado(false);
    };

    const cambioCupo = async (values: any) => {
        //Calculo de la cuota
        const calCuota = {
            pws_monto: detalleSol.Pws_Val_finan,
            pws_nro_cuotas: values.Pws_Val_pla,
            pws_fecha_sol: detalleSol.Pws_fec_solicitud,
            pws_cod_credi: detalleSol.Pws_linea_credito,
            pws_form_plazo: detalleSol.Pws_Periodic,
            pws_identific: values.Pws_Identificacion,
            pws_form_periodic: detalleSol.Pws_Periodic,
        };
        const simulador = await WLOCreditAPIService.getSimulador(calCuota);

        // Calculo del cupo
        const personaRequest = {
            Pws_ident_vende: detalleSol.Pws_ident_vende,
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
            Pws_linea_credito: detalleSol.Pws_linea_credito,
            Pws_Periodic: detalleSol.Pws_Periodic,
            Pws_Tip_person: detalleSol.Pws_Tip_Person,
            Pws_Fideliza: detalleSol.Pws_Fideliza,
            Pws_Tip_Estado: detalleSol.Pws_Tip_Estado,
            Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
            Pws_Nombres: detalleSol.Pws_Nombres,
            Pws_Apellidos1: detalleSol.Pws_Apellidos1,
            Pws_Apellidos2: detalleSol.Pws_Apellidos2,
            Pws_Telefono1: detalleSol.Pws_Telefono1,
            Pws_Telefono2: detalleSol.Pws_Telefono2,
            Pws_Correo: detalleSol.Pws_Correo,
            Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
            Pws_Val_pla: values.Pws_Val_pla,
            Pws_Val_finan: detalleSol.Pws_Val_finan,
            Pws_Val_cuo: simulador.payload.data.R_Val_cuota,
            Pws_Cod_prod: '',
        };
        const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest);

        if (actualizarEstado.payload.result > 0) {
            toast.success(actualizarEstado.payload.message);
            valor.valor.datosInformacionServicio(
                detalleSol.Pws_num_solicitud,
                detalleSol.Pws_Identificacion,
                detalleSol.Pws_Tip_Identificacion
            );
            //Box1(dato={infoCredito} setAction={setPlan})
            valor.valor.consultaEstados(valor.valor.valor);
        } else {
            toast.error(actualizarEstado.payload.message);
        }
    };

    const sendinfo = async () => {
        var date = new Date();
        var dateTime = date.getFullYear() + '/' + date.getDay() + '/' + (date.getMonth() + 1);
        //creacionAvanceRot
        const avanceRot = {
            ps_ident_cli: valor.valor.infoCredito.Pws_Identificacion,
            ps_linea_cred: valor.valor.infoCredito.Pws_linea_credito,
            pn_monto: valor.valor.infoCredito.Pws_Val_finan,
            pn_plazo: valor.valor.infoCredito.Pws_Val_pla,
            pn_for_pla: '1',
            pn_for_pag: '1',
            pn_periodic: '1',
            pf_fecha_sol: dateTime,
            ps_estado: 'A',
        };
        //Validación del cupo con el avance
        if (parseInt(valor.valor.infoCredito.Pws_Val_finan) <= parseInt(cupoConsulta)) {
            avanceRot.pn_monto = valor.valor.infoCredito.Pws_Val_finan;
        } else {
            avanceRot.pn_monto = cupoConsulta;
            toast('Se informa que el avance es superior al cupo a asignar, por lo tanto, se asignara el valor maximo de cupo');
        }
        let response = await WLOCreditAPIService.avanceRot(avanceRot);
        if (response.payload.message == '1') {
            toast(response.payload.result);
            await cambiarEstado('10');
            GeneDocument();
        } else {
            toast.error(response.payload.result);
        }
    };

    const AsingarCupo = async () => {
        var d = new Date(),
            dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/');

        const creaCupo = {
            s_r_ident_cliente: detalleSol.Pws_Identificacion,
            s_r_lin_cred: detalleSol.Pws_linea_credito,
            s_r_fec_ini_cupo: String(dformat),
            s_r_fec_fin_cupo: String(dformat),
            s_r_monto_cupo: cupoConsulta,
        };
        const responde = await WLOCreditAPIService.registraCupo(creaCupo);
        if (responde.payload.data.R_s_cod === '1') {
            cambiarEstado('4');
        }
        toast.success(responde.payload.result);
    };

    const AsingarCupo1 = async (values: any) => {
        var d = new Date(),
            dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/');

        const creaCupo = {
            s_r_ident_cliente: detalleSol.Pws_Identificacion,
            s_r_lin_cred: detalleSol.Pws_linea_credito,
            s_r_fec_ini_cupo: String(dformat),
            s_r_fec_fin_cupo: String(dformat),
            s_r_monto_cupo: values.Pws_Val_cupo,
        };
        const responde = await WLOCreditAPIService.registraCupo(creaCupo);
        if (responde.payload.data.R_s_cod === '1') {
            cambiarEstado('4');
        }
        toast.success(responde.payload.result);
    };

    const GeneDocument = async () => {
        const personaRequest = {
            pws_num_sol: detalleSol.Pws_num_solicitud,
            pws_ident_cli: detalleSol.Pws_Identificacion,
            pws_tip_ident: detalleSol.Pws_Tip_Identificacion,
            pws_cod_desti: '1',
            pws_num_radic: '',
        };
        const generardocumento = await WLOCreditAPIService.GenDocumentGarantia(personaRequest);
        if (generardocumento.payload.data.Resultado == '1') {
            toast.success('Se generaron los documentos correctamente');
        } else {
            toast.error('Los documentos ya han sido generados o el crédito presenta un estado incorrecto');
            setGeneracionLista(true);
        }
    };

    const cambiarEstado = async (estado: any) => {
        const personaRequest = {
            Pws_ident_vende: detalleSol.Pws_ident_vende,
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
            Pws_linea_credito: detalleSol.Pws_linea_credito,
            Pws_Periodic: detalleSol.Pws_Periodic,
            Pws_Tip_person: detalleSol.Pws_Tip_Person,
            Pws_Fideliza: detalleSol.Pws_Fideliza,
            Pws_Tip_Estado: estado,
            Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
            Pws_Nombres: detalleSol.Pws_Nombres,
            Pws_Apellidos1: detalleSol.Pws_Apellidos1,
            Pws_Apellidos2: detalleSol.Pws_Apellidos2,
            Pws_Telefono1: detalleSol.Pws_Telefono1,
            Pws_Telefono2: detalleSol.Pws_Telefono2,
            Pws_Correo: detalleSol.Pws_Correo,
            Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
            Pws_Val_pla: detalleSol.Pws_Val_pla,
            Pws_Val_finan: detalleSol.Pws_Val_finan,
            Pws_Val_cuo: detalleSol.Pws_Val_cuo,
            Pws_Cod_prod: '',
        };
        const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest);
        if (actualizarEstado.payload.result > 0) {
            toast.success(actualizarEstado.payload.message);
            valor.valor.datosInformacionServicio(
                detalleSol.Pws_num_solicitud,
                detalleSol.Pws_Identificacion,
                detalleSol.Pws_Tip_Identificacion
            );
        } else {
            toast.error(actualizarEstado.payload.message);
        }
    };
    const initialValues = {
        Pws_Val_pla: detalleSol.Pws_Val_pla,
    };
    const initialValues1 = {
        Pws_Val_cupo: cupoConsulta,
    };
    return (
        <div className="box">
            <ul className="title">
                <li>10.</li>
                <li>Calificar la Solicitud</li>
                <RedCheck />
            </ul>
            <div className="sub-box redBox">
                <h3>
                    {Titulo}{' '}
                    <NumberFormat value={cupoConsulta} className="txtBlac" displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </h3>
                {valor.valor.infoRetorno.estado != 'Pre-Aprobado' &&
                valor.valor.infoRetorno.estado != 'Desembolsado' &&
                valor.valor.infoRetorno.estado != 'Generacion de Documentos' &&
                valor.valor.infoRetorno.estado != 'Salida Vehículo' ? (
                    <>
                        {Editable ? (
                            <>
                                <ul>
                                    <div className="container">
                                        <p>Ingrese el cupo asignar</p>
                                        <Formik initialValues={initialValues1} onSubmit={AsingarCupo1}>
                                            <Form>
                                                <div className="row">
                                                    <div className="col-6 textright">
                                                        <Field
                                                            className="nopadding form-know-you-item-input"
                                                            id="Pws_Val_cupo"
                                                            name="Pws_Val_cupo"
                                                            placeholder={'prueba'}
                                                            component={ParseNumberSmaller}></Field>
                                                    </div>
                                                    <div className="col-4 textleft">
                                                        <button type="submit" className="cambiarEstado1">
                                                            Asignar
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </ul>
                            </>
                        ) : (
                            <>
                                {Editable1 ? (
                                    <>
                                        <ul>
                                            <div className="container">
                                                <p>Ingrese el plazo para cambiar el cupo</p>
                                                <Formik initialValues={initialValues} onSubmit={cambioCupo}>
                                                    <Form>
                                                        <div className="row">
                                                            <div className="col-6 textright">
                                                                <Field
                                                                    className="nopadding form-know-you-item-input"
                                                                    id="Pws_Val_pla"
                                                                    name="Pws_Val_pla"
                                                                    placeholder={'prueba'}
                                                                    component={ParseNumberSmaller}></Field>
                                                            </div>
                                                            <div className="col-4 textleft">
                                                                <button type="submit" className="cambiarEstado">
                                                                    Recalcular
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </ul>
                                        <ul>
                                            <div className={valor.valor.desembolso ? 'submit' : 'submitDisable'}>
                                                <button
                                                    onClick={() =>
                                                        valor.valor.desembolso
                                                            ? AsingarCupo()
                                                            : toast.success(
                                                                  'Porfavor, revise todas las secciones antes de completar esta acción'
                                                              )
                                                    }>
                                                    {' '}
                                                    Asignar{' '}
                                                </button>
                                            </div>
                                            <div className={valor.valor.desembolso ? 'submit' : 'submitDisable'}>
                                                <button
                                                    onClick={() =>
                                                        valor.valor.desembolso
                                                            ? cambiarEstado('5')
                                                            : toast.success(
                                                                  'Porfavor, revise todas las secciones antes de completar esta acción'
                                                              )
                                                    }>
                                                    {' '}
                                                    Negar{' '}
                                                </button>
                                            </div>
                                        </ul>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="sub-box redBox">
                <h3>
                    Valor primer Avance:{' '}
                    <NumberFormat value={valorAvance} className="txtBlac" displayType={'text'} thousandSeparator={true} prefix={'$'} />{' '}
                </h3>
                {valor.valor.infoRetorno.estado == 'Pre-Aprobado' ? (
                    <>
                        <ul>
                            <div className={valor.valor.desembolso ? 'submit' : 'submitDisable'} style={{ marginTop: '10px' }}>
                                <button
                                    onClick={() => {
                                        sendinfo();
                                    }}>
                                    Asignar
                                </button>
                            </div>
                            <div className={valor.valor.desembolso ? 'submit' : 'submitDisable'} style={{ marginTop: '10px' }}>
                                <button onClick={() => cambiarEstado('5')}>Negar</button>
                            </div>
                        </ul>
                    </>
                ) : (
                    <></>
                )}

                {/* {valor.valor.infoRetorno.estado == "Generacion de Documentos"?<>
                <div className={valor.valor.desembolso? "submitStartR"  : "submitDisable"}style={{marginTop:"10px"}}>
                    <button onClick={()=>{
                        GeneDocument()
                    }}>Generar Documentos</button>
                </div>     
                </>:<> </>
                } */}
            </div>
            {/* <div className="submit">
                <button onClick={()=>{cambiarEstado()}}>Guardar</button>
            </div> */}
            <div className="submit">
                {valor.valor.infoRetorno.estado == 'Generacion de Documentos' ? (
                    <>
                        <button type="button" style={{ marginTop: '10px' }} onClick={() => GeneDocument()}>
                            Generar Documentos
                        </button>
                    </>
                ) : (
                    <></>
                )}
                <button type="button" style={{ marginTop: '10px' }} onClick={() => cambiarEstado('15')}>
                    Salida Vehículo
                </button>
            </div>
        </div>
    );
};

export const Box10_1 = (valor: any) => {
    const [cupoConsulta, setCupoconsulta] = React.useState('');
    const [valorAvance, setValorAvance] = React.useState('');
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    const [Editable, setEditable] = React.useState(false);
    const [Editable1, setEditable1] = React.useState(false);
    const [Titulo, setTitulo] = React.useState('Cupo maximo calculado:');
    useEffect(() => {
        setdetalleSol(valor.valor.infoCredito);
        consultaCupo(valor.valor.Cupoconsulta);
        if (
            valor.valor.Cupoconsulta < valor.valor.valorAFinanciar &&
            valor.valor.infoRetorno.estado == 'Pre-Aprobado' &&
            valor.valor.infoRetorno.estado == 'Desembolsado' &&
            valor.valor.infoRetorno.estado == 'Generacion de Documentos' &&
            valor.valor.infoRetorno.estado == 'Salida Vehículo'
        ) {
            setValorAvance(valor.valor.Cupoconsulta);
        } else {
            setValorAvance(valor.valor.valorAFinanciar);
        }
    });
    const consultaCupo = async (valor: any) => {
        const cuotaRequest = {
            pws_ident_cliente: detalleSol.Pws_Identificacion,
            pws_lin_cre: detalleSol.Pws_linea_credito,
        };
        const consultaCupo = await WLOCreditAPIService.consultaCupoAsignado(cuotaRequest);
        const cupoConsultaResponse = consultaCupo.payload.data;
        if (
            cupoConsultaResponse &&
            cupoConsultaResponse.Res_det_sald &&
            cupoConsultaResponse.Res_det_sald.Res_Det_Saldo.length > 0 &&
            cupoConsultaResponse.Res_det_sald != null &&
            cupoConsultaResponse.R_s_cod === '1'
        ) {
            const cupoConsultaValue = cupoConsultaResponse.Res_det_sald.Res_Det_Saldo[0].R_s_cupo_disp;
            setCupoconsulta(cupoConsultaValue);
            setTitulo('Cupo asignado:');
            setEditable(false);
            setEditable1(false);
        } else {
            if (Math.sign(parseInt(valor)) <= 0) {
                setCupoconsulta('0');
                setEditable(true);
            } else {
                setCupoconsulta(valor);
                setEditable1(true);
            }
        }
    };
    const cambioCupo = async (values: any) => {
        //Calculo de la cuota
        const calCuota = {
            pws_monto: detalleSol.Pws_Val_finan,
            pws_nro_cuotas: values.Pws_Val_pla,
            pws_fecha_sol: detalleSol.Pws_fec_solicitud,
            pws_cod_credi: detalleSol.Pws_linea_credito,
            pws_form_plazo: detalleSol.Pws_Periodic,
            pws_identific: values.Pws_Identificacion,
            pws_form_periodic: detalleSol.Pws_Periodic,
        };
        const simulador = await WLOCreditAPIService.getSimulador(calCuota);
        // Calculo del cupo
        const personaRequest = {
            Pws_ident_vende: detalleSol.Pws_ident_vende,
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: detalleSol.Pws_Tip_Perfil,
            Pws_linea_credito: detalleSol.Pws_linea_credito,
            Pws_Periodic: detalleSol.Pws_Periodic,
            Pws_Tip_person: detalleSol.Pws_Tip_Person,
            Pws_Fideliza: detalleSol.Pws_Fideliza,
            Pws_Tip_Estado: detalleSol.Pws_Tip_Estado,
            Pws_fec_solicitud: detalleSol.Pws_fec_solicitud,
            Pws_Nombres: detalleSol.Pws_Nombres,
            Pws_Apellidos1: detalleSol.Pws_Apellidos1,
            Pws_Apellidos2: detalleSol.Pws_Apellidos2,
            Pws_Telefono1: detalleSol.Pws_Telefono1,
            Pws_Telefono2: detalleSol.Pws_Telefono2,
            Pws_Correo: detalleSol.Pws_Correo,
            Pws_Val_cuoini: detalleSol.Pws_Val_cuoini,
            Pws_Val_pla: values.Pws_Val_pla,
            Pws_Val_finan: detalleSol.Pws_Val_finan,
            Pws_Val_cuo: simulador.payload.data.R_Val_cuota,
            Pws_Cod_prod: '',
        };
        const actualizarEstado = await WLOCreditAPIService.addSolicitud(personaRequest);

        if (actualizarEstado.payload.result > 0) {
            toast.success(actualizarEstado.payload.message);
            valor.valor.datosInformacionServicio(
                detalleSol.Pws_num_solicitud,
                detalleSol.Pws_Identificacion,
                detalleSol.Pws_Tip_Identificacion
            );
            valor.valor.consultaEstados(valor.valor.valor);
        } else {
            toast.error(actualizarEstado.payload.message);
        }
    };
    const initialValues = {
        Pws_Val_pla: detalleSol.Pws_Val_pla,
    };
    return (
        <div className="box">
            <ul className="title">
                <li>9.</li>
                <li>Calificar la Solicitud</li>
                <RedCheck />
            </ul>
            <div className="sub-box redBox">
                <h3>
                    {Titulo}{' '}
                    <NumberFormat value={cupoConsulta} className="txtBlac" displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </h3>
                {valor.valor.infoRetorno.estado != 'Pre-Aprobado' &&
                valor.valor.infoRetorno.estado != 'Desembolsado' &&
                valor.valor.infoRetorno.estado != 'Generacion de Documentos' &&
                valor.valor.infoRetorno.estado != 'Salida Vehículo' ? (
                    <>
                        {Editable1 ? (
                            <>
                                <div className="container">
                                    <p>Ingrese el plazo para cambiar el cupo</p>
                                    <Formik initialValues={initialValues} onSubmit={cambioCupo}>
                                        <Form>
                                            <div className="row">
                                                <div className="col-6 textright">
                                                    <Field
                                                        className="nopadding form-know-you-item-input"
                                                        id="Pws_Val_pla"
                                                        name="Pws_Val_pla"
                                                        placeholder={'prueba'}
                                                        component={ParseNumberSmaller}></Field>
                                                </div>
                                                <div className="col-4 textleft">
                                                    <button type="submit" className="cambiarEstado">
                                                        Recalcular
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="sub-box redBox">
                <h3>
                    Valor primer Avance:{' '}
                    <NumberFormat value={valorAvance} className="txtBlac" displayType={'text'} thousandSeparator={true} prefix={'$'} />{' '}
                </h3>
            </div>
        </div>
    );
};

export const Box12 = (valor: any) => {
    const { dataDesem } = valor.valor;
    const [infoReferenciacion, setinfoReferenciacion] = React.useState('');
    const [infoButtonSummit, setinfoButtonSummit] = React.useState('');

    useEffect(() => {
        setinfoReferenciacion(valor.valor.referenciacion.Pws_observacion);
        if (infoReferenciacion === undefined) {
            setinfoButtonSummit('Agregar');
        } else {
            setinfoButtonSummit('Actualizar');
        }
    });

    const guardarObservacion = async (values: any) => {
        let userPerfil = sessionStorage.getItem('User');
        const references = {
            Pws_num_solicitud: valor.valor.valor[0],
            Pws_Identificacion_aud: JSON.parse(userPerfil!).idUser,
            Pws_observacion: values.Pws_descrip_cred,
        };
        const referenciacion = await WLOCreditAPIService.getreferenciacion(references);
        if (referenciacion.payload.data.Resultado == '1') {
            toast.success(referenciacion.payload.data.MensajeError);
        } else {
            toast.error(referenciacion.payload.data.MensajeError);
        }
    };

    return (
        <div className="box">
            <ul className="title">
                <li>12.</li>
                <li>Referenciación</li>
                <RedCheck />
            </ul>
            <Formik enableReinitialize={true} initialValues={{ Pws_descrip_cred: infoReferenciacion }} onSubmit={guardarObservacion}>
                {({ errors, isSubmitting, values }) => {
                    return (
                        <>
                            <Form>
                                <ul>
                                    <li>
                                        <Field
                                            as="textarea"
                                            id="Pws_descrip_cred"
                                            name="Pws_descrip_cred"
                                            className="form-know-you-item-input-text-area-referenciacion display-flex"
                                            placeholder="Ingrese las referencias del credito"
                                        />
                                    </li>
                                </ul>
                               { dataDesem.estado != "Desembolsado"?
                            
                                <div className="submit">
                                <button type="submit" style={{ marginTop: '10px' }}>
                                    {infoButtonSummit}
                                </button>
                                </div>
                                :
                                <>
                                </>
                               }
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

interface RenderContainerProps {
    setPopup: React.Dispatch<React.SetStateAction<string>>;
    setPlan: React.Dispatch<React.SetStateAction<boolean>>;
    setDatosP: React.Dispatch<React.SetStateAction<any>>;
}

export const DetailCredit = (props: RenderContainerProps) => {
    const { setPopup, setPlan, setDatosP  } = props;
    const [datosP, setDatosPInternal] = React.useState<any>();
    const navigate = useNavigate();
    const buscar = useLocation();
    const [persona, DatoPersona] = React.useState({});
    const [dataConyuge, setDataConyuge] = React.useState({} as any);
    const [estado, setEstado] = React.useState('');
    const [datoList, setDatoList] = React.useState(data);
    const [datoListGestor, setDatoListGestor] = React.useState(dataGestor);
    const [desembolso, setDesembolso] = React.useState(false);
    const [datosReferenciaPersonales, ReferenciaPersonales] = React.useState([]);
    const [datoFinancie, InfoFinanc] = React.useState({});
    const [datosValorRefer, DatosReferencia] = React.useState({});
    const [dataDesem, datosDesembolso] = React.useState({
        score: 0,
        DataCredito: false,
        listaRestrictiva: false,
        sagrilaft: false,
        estado: 'Simulador',
        color: '1',
    });
    const [infoCredito, setInformacionCredito] = React.useState({});
    const [valorFinanciar, setDataValorFinanciar] = React.useState('');
    const [alldocuments, setDocuments] = React.useState([]);
    const [valorPeps, VAlorPerp] = React.useState([]);
    const [riesgo, setRiesto] = React.useState(false);
    const [arregloValor, valorArreglo] = React.useState([] as any);
    const [propetarios, setDataPropetario] = React.useState([] as any);
    const [requeridos, setDataRequerido] = React.useState([] as any);
    const { request, setRequest } = useRequestContext();
    const [listadoCon, setListadoCon] = React.useState([] as any);
    const [Cupoconsulta, setCupoconsulta] = React.useState('');
    const [userPermisos, setuserPermisos] = React.useState(false);
    const [infoReferenciacion, setinfoReferenciacion] = React.useState([] as any);

    useEffect(() => {
        if (buscar) {
            var valorBusqueda = buscar.search.replace('?', '');
            let arreglo = String(valorBusqueda).split('-');
            getConsultas(arreglo);
            valorArreglo(arreglo);
            consultaEstados(arreglo);
            // Si el usuario es gestor, habilita los permisos correspondiente
            let userPerfil = sessionStorage.getItem('User');
            if (JSON.parse(userPerfil!).idProfile === '1') {
                setuserPermisos(true);
                DatosReferenciacion(arreglo[0]);
            }
        }
        return () => {
            console.log("Desmontando DetailCredit")
            sessionStorage.setItem("uploadedDocuments", "false");
        }
    }, [buscar, navigate]);
    const handleDatosPChange = (newDatosP: any) => {
        setDatosPInternal(newDatosP);
        setDatosP(newDatosP);
    };
    const consultaEstados = async (arreglo: any) => {
        const consultaCupo = await WLOCreditAPIService.consultaCupo({
            Pws_Solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_num_Tip_Ident: arreglo[2],
        });
        const cupo = consultaCupo.payload.data.Cupo;
        setCupoconsulta(cupo);
    };
    const getConsultas = (arreglo: string[]) => {
        datosInformacionServicio(arreglo[0], arreglo[1], arreglo[2]);
        let sends: WloCreditoService.SolicitudDetalla = {
            Pws_Num_Solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_Tip_Identificacion: arreglo[2],
            Pws_Perfil_Acceso: '',
            Pws_Fec_Solicitud: '',
        };
        if (arreglo.length > 0) {
            WLOCreditAPIService.consultaSolicitudCygnusDetal(sends).then(
                (valor) => {
                    if (Number(valor.payload.result) > 0) {
                        if (valor.payload.data.datos_persona) {
                            if (valor.payload.data.datos_persona.Datos_persona.length > 0) {
                                DatoPersona(valor.payload.data.datos_persona.Datos_persona[0]);
                                let dataCygnusCony = valor.payload.data.datos_conyugue?.Datos_conyugue[0];
                                const dataInicialConyuge = {
                                    Pws_Con_nomsol: dataCygnusCony?.Pws_Con_nomsol,
                                    Pws_Con_Tip_identif: dataCygnusCony?.Pws_Con_Tip_identif,
                                    Pws_Con_identif: dataCygnusCony?.Pws_Con_identif,
                                    Pws_Con_tel:dataCygnusCony?.Pws_Con_tel,
                                }
                                setDataConyuge(dataInicialConyuge);
                            }
                        }
                        if (valor.payload.data.datos_operfinan) {
                            if (valor.payload.data.datos_operfinan.Datos_operfinan.length > 0) {
                                InfoFinanc(valor.payload.data.datos_operfinan.Datos_operfinan[0]);
                            }
                        }
                        if (valor.payload.data.datos_peps) {
                            if (valor.payload.data.datos_peps.Datos_peps.length > 0) {
                                VAlorPerp(valor.payload.data.datos_peps.Datos_peps[0]);
                            }
                        }
                        DatosReferenciasPersonales(arreglo[0], arreglo[1], arreglo[2]);
                    }
                },
                (error) => {}
            );
        }
    };
    const Busquedatotal = () => {
        getConsultas(arregloValor);
    };
    const consultarWsCodeudor = async (valor: string, valor2: string, valor3: string) => {
        let codeudors: any = {
            Pws_codigo: '',
            Pws_num_solicitud: valor,
            Pws_Identificacion: valor2,
            Pws_Radic_Codeu: valor,
            Pws_Tip_Identificacion: valor3,
            Pws_Iden_Code: '',
            Pws_Tip_Identificacion_Code: '',
            Pws_Tip_Codeu: '',
        };
        const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors);
        if (Number(allCodeudor.payload.result) > 0) {
            if (allCodeudor.payload.data.datos_codeu.Datos_codeudor.length > 0) {
                agregarValorDeudor(allCodeudor.payload.data.datos_codeu.Datos_codeudor, 0, valor, [], []);
            }
        }

        extraerdocumentos(valor, valor2, valor3);
    };
    const agregarValorDeudor = async (datosCodeudor: any, id: any, numsol: any, arreglo: any, arreglo1: any) => {
        let retprp: any[] = [];
        retprp = retprp.concat(arreglo);
        let retprp2: any[] = [];
        retprp2 = retprp2.concat(arreglo1);

        if (datosCodeudor.length <= id) {
            setDataRequerido(retprp);
            setDataPropetario(retprp2);
        } else {
            let sends: WloCreditoService.SolicitudDetalla = {
                Pws_Num_Solicitud: numsol,
                Pws_Identificacion: datosCodeudor[id].Pws_Iden_Code,
                Pws_Tip_Identificacion: datosCodeudor[id].Pws_Tip_Identificacion_Code,
                Pws_Perfil_Acceso: '',
                Pws_Fec_Solicitud: '',
            };

            let retorno = await WLOCreditAPIService.consultaSolicitudCygnus(sends);
            if (Number(retorno.payload.result) > 0) {
                if (datosCodeudor[id].Pws_Tip_Codeu === '2') {
                    let dato = {
                        codeudor: datosCodeudor[id],
                        data: retorno.payload.data.datos_persona.Datos_persona[0],
                    };
                    retprp.push(dato);
                } else {
                    let dato = {
                        codeudor: datosCodeudor[id],
                        data: retorno.payload.data.datos_persona.Datos_persona[0],
                    };
                    retprp2.push(dato);
                }

                agregarValorDeudor(datosCodeudor, id + 1, numsol, retprp, retprp2);
            }
        }
    };
    const datosInformacionServicio = async (valor: string, valor2: string, valor3: string) => {
        const result = await WLOCreditAPIService.addSolicitud({
            Pws_num_solicitud: valor,
            Pws_Identificacion: valor2,
            Pws_Tip_Identificacion: valor3, //Valor que pide el ws pero a nivel de UI no existe
        });
        if (result.payload.result >= 0 || result.payload.result == -4) {
            let tipoEstado = 'Simulador';
            let colorestado = '1';
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 1) {
                tipoEstado = 'Simulador';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 2) {
                tipoEstado = 'Confirmación información';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 3) {
                tipoEstado = 'Firma documentos';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 4) {
                tipoEstado = 'Pre-Aprobado';
                colorestado = '2';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 5) {
                tipoEstado = 'Rechazado';
                colorestado = '3';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 6) {
                tipoEstado = 'Eliminado';
                colorestado = '3';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 7) {
                tipoEstado = 'Pendiente CR';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 8) {
                tipoEstado = 'Pendiente CP';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 9) {
                tipoEstado = 'Consulta Tercero';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 10) {
                tipoEstado = 'Generacion de Documentos';
                colorestado = '2';
                const estado = sessionStorage.setItem('estado', tipoEstado);
                setEstado(tipoEstado);
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 11) {
                tipoEstado = 'Desembolsado';
                colorestado = '2';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 12) {
                tipoEstado = 'En estudio';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 14) {
                tipoEstado = 'Pendiente Con Codeudores';
                colorestado = '1';
            }
            if (Number(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado) == 15) {
                tipoEstado = 'Salida Vehículo';
                colorestado = '2';
            }
            dataDesem.estado = tipoEstado;

            dataDesem.color = colorestado;
            datosDesembolso(dataDesem);
            setRequest({
                ...request,
                valorSolicitud: result.payload.data.Datos_Sol.Datos_Sol[0],
            });
            setInformacionCredito(result.payload.data.Datos_Sol.Datos_Sol[0]);
            setDataValorFinanciar(result.payload.data.Datos_Sol.Datos_Sol[0].Pws_Val_finan);
        }

        consultarWsCodeudor(valor, valor2, valor3);
    };
    const extraerdocumentos = async (valor: string, valor2: string, valor3: string) => {
        const result = await WLOCreditAPIService.ConsultarDocumentos({
            Pws_num_solicitud: valor,
            Pws_Identificacion: valor2,
            Pws_Tip_Identificacion: valor3, //Valor que pide el ws pero a nivel de UI no existe
        });
        if (result.payload.data.Pws_R_s_cod == '1') {
            if (result.payload.data.datos_doc.datos_Doc) {
                setDocuments(result.payload.data.datos_doc.datos_Doc);
            } else {
                setDocuments([]);
            }
        }
        extraerdatcredRiesgo(valor, valor2, valor3);
    };

    const extraerdatcredRiesgo = async (valor: string, valor2: string, valor3: string) => {
        const result = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
            Pws_num_solicitud: valor,
            Pws_Identificacion: valor2,
            Pws_Tip_Identificacion: valor3, //Valor que pide elcomo  ws pero a nivel de UI no existe
        });
        var i_codigo = '';
        const entidadter = await AdminService.entidadTer(i_codigo);
        if (result.payload) {
            if (result.payload.data) {
                if (result.payload.data.datos_datacre) {
                    if (result.payload.data.datos_datacre.datos_datacredito) {
                        var listado = new Array();
                        var listDataCredito = result.payload.data.datos_datacre.datos_datacredito;
                        for (let index = 0; index < listDataCredito.length; index++) {
                            let listadoConsultas = {
                                estado_restop: String,
                                tipo_valida: String,
                                c_descripcion: String,
                                puntaje: String,
                                cuotas_data: String,
                            };

                            listadoConsultas.estado_restop = listDataCredito[index].Pws_estado_resotp;
                            listadoConsultas.tipo_valida = listDataCredito[index].Pws_Tip_val;
                            listadoConsultas.c_descripcion =
                                entidadter.payload.data.ListRef.Datos_Referencias[
                                    parseInt(listDataCredito[index].Pws_Entidad_Consul) - 1
                                ].s_descripcion;
                            listadoConsultas.puntaje = listDataCredito[index].Pws_Puntaje;
                            listadoConsultas.cuotas_data = listDataCredito[index].n_Pws_Num_cuodat;
                            listado[index] = listadoConsultas;
                            if (Number(listDataCredito[index].Pws_Entidad_Consul) == 2) {
                                dataDesem.sagrilaft = Number(listDataCredito[index].Pws_estado_resotp) == 1 ? true : false;
                                datosDesembolso(dataDesem);
                                if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                    setRiesto(true);
                                }
                            }
                            if (Number(listDataCredito[index].Pws_Entidad_Consul) == 4) {
                                dataDesem.DataCredito = Number(listDataCredito[index].Pws_estado_resotp) == 1 ? true : false;
                                datosDesembolso(dataDesem);
                                if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                    setRiesto(true);
                                }
                            }

                            if (Number(listDataCredito[index].Pws_Entidad_Consul) == 5) {
                                if (Number(listDataCredito[index].Pws_estado_resotp) == 1) {
                                    setRiesto(true);
                                }
                                dataDesem.score = Number(listDataCredito[index].Pws_Puntaje);
                            }
                        }
                        setListadoCon(listado);
                    }
                }
            }
        }
    };
    const DatosReferenciasPersonales = (valor: string, valor2: string, valor3: string) => {
        WLOCreditAPIService.addReferences({
            s_num_solicitud: valor!,
            s_identificacion: valor2!,
            s_tipo_identificacion: valor3!,
            s_ref_tipo: '',
            s_ref_parentes: '',
            s_ref_nomcomple: '',
            s_ref_telfij: '',
            s_ref_telcel: '',
        }).then((ValorReferencias) => {
            if (Number(ValorReferencias.payload.result) > 0) {
                ReferenciaPersonales(ValorReferencias.payload.data.datos_referencia.datos_refer);
            }
            DatosReferencia({
                s_num_solicitud: valor!,
                s_identificacion: valor2!,
                s_tipo_identificacion: valor3!,
                s_ref_tipo: '',
                s_ref_parentes: '',
                s_ref_nomcomple: '',
                s_ref_telfij: '',
                s_ref_telcel: '',
            });
        });
    };

    const DatosReferenciacion = async (valor: string) => {
        const references = {
            Pws_num_solicitud: valor,
            Pws_Identificacion_aud: '',
            Pws_observacion: '',
        };
        const referenciacion = await WLOCreditAPIService.getreferenciacion(references);
        if (referenciacion.payload.data.Resultado == '1') {
            setinfoReferenciacion(referenciacion.payload.data.Datos_observacion.Datos_observacion[0]);
        }
    };

    const changeDatoList = (id: number) => {
        let valor: {
            bg1: string;
            text: string;
            bg2: string;
            abcilon: string;
            revisado: boolean;
        }[] = [];
        valor = valor.concat(datoListGestor);
        valor[id].abcilon = '';
        valor[id].bg2 = 'none';
        valor[id].bg1 = '#1B9543';
        valor[id].revisado = true;
        setDatoList(valor);
        for (let i = 0; i < 9; i++) {
            // 9 es la ultima sección, antes de la calificacion de solicitud
            if (datoListGestor[i].revisado == false) {
                setDesembolso(false);
                i = 9;
            } else {
                setDesembolso(true);
            }
        }
    };

    return (
        <div className="detailCredit detail">
            <div className="header">
                <Fab
                    variant="extended"
                    color="neutral"
                    aria-label="add"
                    className="hooverButtonRed"
                    onClick={() => {
                        navigate(AppRoutes.SOLICITUDES_DE_CREDITO, { replace: true });
                    }}>
                    <ArrowBack className="hooverButtonRedsvg" sx={{ mr: 1 }} />
                    {'Atrás'}
                </Fab>
                <h2 className="title">Detalle del crédito</h2>
                <p className={dataDesem.color == '1' ? 'estadoMedio' : dataDesem.color == '2' ? 'estadoAceptado' : 'estadoNegado'}>
                    {' '}
                    {dataDesem.estado}{' '}
                </p>
            </div>
            <div className="body">
                <div className="sidebar">
                    <h4>Solicitud</h4>
                    {userPermisos ? (
                        <>
                            {datoListGestor.map((info, i) => {
                                return <CreditSideBar key={i} info={info} i={i} datodesembolso={desembolso} />;
                            })}
                        </>
                    ) : (
                        <>
                            {datoList.map((info, i) => {
                                return <CreditSideBar key={i} info={info} i={i} datodesembolso={desembolso} />;
                            })}
                        </>
                    )}
                </div>
                <div className="mainDetail">
                    <Box1 dato={infoCredito} person={persona} setAction={setPlan} setDatosP={setDatosP} onDatosPChange={handleDatosPChange} />
                    <Box2 valorPersonal={{ dataConyuge: dataConyuge, person: persona, Busquedatotal, setPopup, changeDatoList, userPermisos, infoCredito: infoCredito, dataDesem:dataDesem}} />
                    <Box3
                        valorFinancie={{
                            valorinic: datoFinancie,
                            datopeps: valorPeps,
                            Busquedatotal,
                            datosIngr: arregloValor,
                            person: persona,
                            changeDatoList,
                            userPermisos,
                            dataDesem:dataDesem,
                        }}
                    />
                    <Box4
                        valorDocuments={{ alldocuments: alldocuments, changeDatoList, valor: arregloValor, Busquedatotal, userPermisos, dataDesem:dataDesem,}}
                    />
                    <Box5
                        valorReferencias={{
                            datosrefe: datosReferenciaPersonales,
                            Busquedatotal,
                            values: datosValorRefer,
                            changeDatoList,
                            userPermisos,
                            dataDesem
                        }}
                    />
                    <Box6 propetarios={{ valores: propetarios, arreglos: arregloValor, changeDatoList, userPermisos, dataDesem: dataDesem}} />
                    <Box7
                        requeridos={{
                            valores: requeridos,
                            arreglos: arregloValor,
                            changeDatoList,
                            infodeSolicitud: infoCredito,
                            userPermisos,
                            dataDesem: dataDesem
                        }}
                    />
                    <Box8
                        dato={{
                            setDesembolso,
                            infoRetorno: dataDesem,
                            changeDatoList,
                            valorAFinanciar: valorFinanciar,
                            infoCredito,
                            infodeSolicitud: infoCredito,
                            Cupoconsulta,
                            userPermisos,
                        }}
                    />
                    {userPermisos ? (
                        <>
                            <Box9
                                terceros={{
                                    valores: listadoCon,
                                    arreglos: arregloValor,
                                    changeDatoList,
                                    infoCredito,
                                    datosInformacionServicio,
                                    userPermisos,
                                }}
                            />
                            <Box10
                                valor={{
                                    valor: arregloValor,
                                    setDesembolso,
                                    infoRetorno: dataDesem,
                                    changeDatoList,
                                    valorAFinanciar: valorFinanciar,
                                    infoCredito,
                                    Cupoconsulta,
                                    desembolso,
                                    datosInformacionServicio,
                                    consultaEstados,
                                    userPermisos,
                                }}
                            />
                            <Box11
                                valorDocuments={{
                                    alldocuments: alldocuments,
                                    changeDatoList,
                                    valor: arregloValor,
                                    Busquedatotal,
                                    infoCredito,
                                    infodeSolicitud: infoCredito,
                                    userPermisos,
                                    dataDesem: dataDesem,
                                }}
                            />
                            <Box12 valor={{ valor: arregloValor, referenciacion: infoReferenciacion, dataDesem: dataDesem, }} />
                        </>
                    ) : (
                        <>
                            <Box10_1
                                valor={{
                                    valor: arregloValor,
                                    setDesembolso,
                                    infoRetorno: dataDesem,
                                    changeDatoList,
                                    valorAFinanciar: valorFinanciar,
                                    infoCredito,
                                    Cupoconsulta,
                                    desembolso,
                                    datosInformacionServicio,
                                    consultaEstados,
                                    userPermisos,
                                }}
                            />
                            <Box11
                                valorDocuments={{
                                    alldocuments: alldocuments,
                                    changeDatoList,
                                    valor: arregloValor,
                                    Busquedatotal,
                                    infoCredito,
                                    infodeSolicitud: infoCredito,
                                    userPermisos,
                                    dataDesem: dataDesem,
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export const Box9 = (terceros: any) => {
    const [revisado, setRevisado] = React.useState(true);
    const [tercero, agregapro] = React.useState([] as any);
    const [infoPers, setInfoPers] = React.useState([] as any);
    const [revisadoDisable, setRevisadoDisable] = React.useState(true);
    useEffect(() => {
        agregapro(terceros.terceros.valores);
        setInfoPers(terceros.terceros.infoCredito);
        if (terceros.terceros.valores.length == 0) {
            setRevisadoDisable(false);
        } else {
            setRevisadoDisable(true);
        }
    }, [terceros]);
    return (
        <div className="box">
            <ul className="title">
                <li>9.</li>
                <li>Consulta a terceros</li>
                <Person />
            </ul>
            <div className="table">
                <ConsultaTercerosTable terceros={{ tercero: tercero, infoPers: infoPers }} />
            </div>
            <div className="submit">
                {revisado ? (
                    <>
                        <button
                            type="button"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                terceros.terceros.changeDatoList(8);
                                setRevisado(false);
                            }}>
                            Revisado
                        </button>
                    </>
                ) : (
                    <>
                        <GreenCheck />
                    </>
                )}
            </div>
        </div>
    );
};

export const ConsultaTercerosTable = (terceros: any) => {
    const [tercero, agregapro] = React.useState([] as any);
    const [infoPers, setInfoPers] = React.useState([] as any);
    useEffect(() => {
        agregapro(terceros.terceros.tercero);
        setInfoPers(terceros.terceros.infoPers);
    }, [terceros]);
    return (
        <div className="referenceTable">
            <div className="table">
                <div className="responsiveFix">
                    <div className="responsiveAuto">
                        <ul>
                            <li className="listItem listHeader">Entidad</li>
                            <li className="listItem listHeader">Puntaje</li>
                            <li className="listItem listHeader">Cuotas Data</li>
                            <li className="listItem listHeader">Riesgo</li>
                            <li className="listItem listHeader">Consultar</li>
                        </ul>
                        {tercero?.map((info: any, i: any) => {
                            return <SolicItem key={i} info={info} infoPers={infoPers} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SolicItem = (info: any) => {
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    useEffect(() => {
        setdetalleSol(info.infoPers);
    });

    const datacredito = async () => {
        const estadoTerce = await AdminService.entidadTer('');
        let puntaje = 0;
        let cuotaData = 0;
        let consulta;
        let estadoDatacredito = '3';
        let sends: WloCreditoService.SolicitudDetalla = {
            Pws_Num_Solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Ideoastntificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Perfil_Acceso: '',
            Pws_Fec_Solicitud: '',
        };
        consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends);

        const data4 = {
            identificacion: detalleSol.Pws_Identificacion,
            primer_apellido: consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1,
            tipoIdentificacion: String(
                typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(
                    (valor) => valor.s_codigo == Number(detalleSol.Pws_Tip_Identificacion)
                )[0].s_datacredito
            ),
        };
        const response3 = await WLOCreditAPIService.consultDataCredito2(data4);
        if (response3.payload.success) {
            puntaje = response3.payload.data.Informes.Informe[0].Score[0].$.puntaje;
            cuotaData = response3.payload.data.Informes.Informe[0].InfoAgregada[0].Resumen[0].Saldos[0].$.cuotaMensual;
            if (puntaje >= 1 && puntaje < 500) {
                estadoDatacredito = '1';
            } else if (puntaje >= 500) {
                estadoDatacredito = '0';
            }
        }
        const data5 = {
            Pws_num_solicitud: String(detalleSol.Pws_num_solicitud),
            Pws_Identificacion: String(detalleSol.Pws_Identificacion!),
            Pws_Tip_Identificacion: String(detalleSol.Pws_Tip_Identificacion!),
            Pws_Fec_gen: String(Moment(new Date()).format('MM/DD/YYYY')),
            Pws_estado_resotp: estadoDatacredito,
            Pws_Tip_val: '2',
            Pws_Puntaje: String(puntaje),
            Pws_Entidad_Consul: '4',
            Pws_Num_cuodat: String(cuotaData * 1000),
        };
        if (estadoTerce.payload.data.ListRef.Datos_Referencias[2].s_descripcion_legal == '1') {
            const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
            toast.success(response5.payload.data.Pws_R_s_mensaje);
        } else {
            toast.error(response3.payload.data.mensaje);
        }
    };

    const sagrilaft = async () => {
        let sagrilaftRiesgo;
        let tipodocumento = '';
        if (detalleSol.Pws_Tip_Identificacion === '12') tipodocumento = 'ti';
        if (detalleSol.Pws_Tip_Identificacion === '13') tipodocumento = 'cc';
        if (detalleSol.Pws_Tip_Identificacion === '22') tipodocumento = 'ce';
        if (detalleSol.Pws_Tip_Identificacion === '31') tipodocumento = 'nit';
        if (detalleSol.Pws_Tip_Identificacion === '41') tipodocumento = 'pa';

        const data = {
            datoConsultar: detalleSol.Pws_num_solicitud, //19123402
            tipoDocumento: tipodocumento,
            numeroSolicitud: detalleSol.Pws_num_solicitud, //No tenemos un ws que nos diga el nro de solicitud
            fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
            email: '',
        };

        const response = await WLOCreditAPIService.consultaConsolidada(data);
        if (response.payload.presentaRiesgo) {
            sagrilaftRiesgo = '1';
        } else {
            sagrilaftRiesgo = '0';
        }
        //guarda sagrilaft
        const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: sagrilaftRiesgo,
            Pws_Tip_val: '1',
            Pws_Puntaje: '0',
            Pws_Entidad_Consul: '2',
            Pws_Num_cuodat: '',
        });
    };

    const consultaScore = async () => {
        let scorepuntaje;
        let enestudio;
        let scorecygnus;
        const response10 = await WLOCreditAPIService.EvaluaScore({
            ps_radicado: String(detalleSol.Pws_num_solicitud),
            ps_identificacion: String(detalleSol.Pws_Identificacion),
            ps_cod_lin: String(detalleSol.Pws_linea_credito),
            ps_tipoCliente: String(detalleSol.Pws_tipo_cliente),
            ps_tip_deucode: '1',
        });        
        if (response10.payload.data.estado === 'APROBADO') {
            scorecygnus = true;
        } else {
            enestudio = true;
        }
        scorepuntaje = response10.payload.data.puntaje_obtenido;

        const data6 = {
            Pws_num_solicitud: String(detalleSol.Pws_num_solicitud),
            Pws_Identificacion: String(detalleSol.Pws_Identificacion),
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: scorecygnus ? '0' : '1',
            Pws_Tip_val: '4',
            Pws_Puntaje: String(scorepuntaje),
            Pws_Entidad_Consul: '5',
            Pws_Num_cuodat: '',
        };
        const response6 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data6);
    };

    const irpara = (descripcion: string) => {
        if (descripcion == 'Score Cygnus') {
            consultaScore();
        }
        if (descripcion == 'DataCredito') {
            datacredito();
        }
        if (descripcion == 'Sagrilaft') {
            sagrilaft();
        }
    };
    return (
        <>
            <ul>
                <li className="listItem firstItem">{info.info.c_descripcion}</li>
                <li className="listItem">{info.info.puntaje} </li>
                <li className="listItem">
                    {info.info.cuotas_data == '0' ? (
                        '-'
                    ) : (
                        <NumberFormat value={info.info.cuotas_data} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    )}
                </li>
                <li className="listItem">{info.info.estado_restop == '1' ? 'SI' : 'NO'} </li>

                <li className="listItem">
                    {info.info.estado_restop == '3' ? (
                        <div
                            onClick={() => {
                                irpara(info.info.c_descripcion);
                            }}>
                            <Search />
                        </div>
                    ) : (
                        <div>
                            <GreenCheck />
                        </div>
                    )}
                </li>
            </ul>
        </>
    );
};

export const Box11 = (valorDocuments: any) => {  
    const [update, setUpdate] = React.useState(true);
    const [UpdateDocumentosLegales, setUpdateDocumentosLegales] = React.useState(true);
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    const [infoCredito, setInformacionCredito] = React.useState({});
    const [alldocuments2, setDocuments2] = useState([
        {
            Pws_Doc_Tipo: 6,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 7,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 8,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 9,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 10,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 11,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 12,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 13,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 14,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
    ] as any);
    const [refresh, setRefresh] = useState(true);
    const [alldocuments1, setDocuments] = React.useState([
        {
            Pws_Doc_Tipo: 6,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 7,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 8,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 9,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 10,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 11,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 12,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 13,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
        {
            Pws_Doc_Tipo: 14,
            Pws_Doc_nombre: '',
            Pws_Clave_doc: '',
            Pws_Doc_estado: '',
            Pws_Doc_ruta: '',
        },
    ]);

   const obtenerDocumentosGaranGenerados = async(array: any) => {
           return array.map(async (element:any)  => {
            const credencialFtp= process.env.REACT_APP_CREDENCIAL_FTP;
            const personaRequest = {
                Pws_num_solicitud: valorDocuments.valorDocuments.infoCredito.Pws_num_solicitud,
                Pws_Identificacion: valorDocuments.valorDocuments.infoCredito.Pws_Identificacion,
            }; 
            const headers = {
                'Authorization': 'Basic '+credencialFtp,
                'Accept': '*/*', // Puedes agregar otros headers según sea necesario
            };
            let nombreDoc: string;
            let num: string;
            let numDoc = element.Pws_Doc_Tipo;
            let cargado: any;
            
            
            if (numDoc == 6) {
                nombreDoc = 'Pagare';
                num='29';
            } else if (numDoc == 7) {
                nombreDoc = 'Cont_aval';
                num='47';
            } else if (numDoc == 8) {
                nombreDoc = 'Garant_mobi';
                num='51';
            } else if (numDoc == 9) {
                nombreDoc = 'Compraventa';
                num='52';
            } else if (numDoc == 10) {
                nombreDoc = 'Dacion_pag';
                num='53';
            } else if (numDoc == 11) {
                nombreDoc = 'Cond_Fin';
                num='54';
            } else if (numDoc == 12) {
                nombreDoc = 'Acta_retencion_vehiculo';
                num='55';
            } else if (numDoc == 13) {
                nombreDoc = 'A_entregaParcial';
                num='56';
            } else if (numDoc == 14) {
                nombreDoc = 'compra_venta_autentificado_';
                num='57';
            }else {
                return;
            }

            const getDocumento = async() => {
                let url = process.env.REACT_APP_DOCUMENT +
                personaRequest.Pws_Identificacion +
                '/' +
                nombreDoc +
                '_' +
                personaRequest.Pws_num_solicitud +
                '_' + num +
                '.pdf'; 
                
                try {
                    let x = await axios.get(url, { headers });
                    return {...element, cargado:true};
                } catch (error) {
                    return {...element, cargado: false};
                }
            }
            return await getDocumento();
          
           
        });
        //setDataDocCargados(dataDocCargados2);
    }


    const obtenerDocumentosGaranCargadosPorVendedor = async(array: any) => {
        return array.map(async (element:any)  => {
         const credencialFtp= process.env.REACT_APP_CREDENCIAL_FTP;
         const personaRequest = {
             Pws_num_solicitud: valorDocuments.valorDocuments.infoCredito.Pws_num_solicitud,
             Pws_Identificacion: valorDocuments.valorDocuments.infoCredito.Pws_Identificacion,
         }; 
         const headers = {
             'Authorization': 'Basic '+credencialFtp,
             'Accept': '*/*', // Puedes agregar otros headers según sea necesario
         };
         let nombreDoc: string;
         let num: string;
         let numDoc = element.Pws_Doc_Tipo;
         let cargado: any;
         
         
         if (numDoc == 6) {
             nombreDoc = 'Pagare';
             num='29';
         } else if (numDoc == 7) {
             nombreDoc = 'Cont_aval';
             num='47';
         } else if (numDoc == 8) {
             nombreDoc = 'Garant_mobi';
             num='51';
         } else if (numDoc == 9) {
             nombreDoc = 'Compraventa';
             num='52';
         } else if (numDoc == 10) {
             nombreDoc = 'Dacion_pag';
             num='53';
         } else if (numDoc == 11) {
             nombreDoc = 'Cond_Fin';
             num='54';
         } else if (numDoc == 12) {
             nombreDoc = 'Acta_retencion_vehiculo';
             num='55';
         } else if (numDoc == 13) {
             nombreDoc = 'A_entregaParcial';
             num='56';
         } else if (numDoc == 14) {
             nombreDoc = 'compra _venta _autentificado';
             num='57';
         }else {
             return;
         }

         const getDocumento = async() => {
             let url = process.env.REACT_APP_DOCUMENT +
             personaRequest.Pws_Identificacion +
             '/' +
             nombreDoc +
             '_' +
             personaRequest.Pws_num_solicitud +
             '_' + num +
             '_F.pdf'; 
             
             try {
                 let x = await axios.get(url, { headers });
                 return {...element, cargado:true};
             } catch (error) {
                 return {...element, cargado: false};
             }
         }
         return await getDocumento();
       
        
     });
     //setDataDocCargados(dataDocCargados2);
 }

    const { user, setUser } = useLoginContext();
    useEffect(() => {
        const fun = async() => {
            let uploadedDocuments = sessionStorage.getItem("uploadedDocuments") || "false";
            if (valorDocuments.valorDocuments.infoCredito && valorDocuments.valorDocuments.infoCredito?.Pws_Identificacion && uploadedDocuments == "false") {
                sessionStorage.setItem("uploadedDocuments", "true");
                    let valordocumentos = [
                        {
                            Pws_Doc_Tipo: 6,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 7,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 8,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 9,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 10,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 11,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 12,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 13,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 14,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                    ] as any;
                    
                    let valordocumentos2 = [
                        {
                            Pws_Doc_Tipo: 6,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 7,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 8,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 9,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 10,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 11,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 12,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 13,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                        {
                            Pws_Doc_Tipo: 14,
                            Pws_Doc_nombre: '',
                            Pws_Clave_doc: '',
                            Pws_Doc_estado: '',
                            Pws_Doc_ruta: '',
                        },
                    ] as any;
                
                    valorDocuments.valorDocuments.alldocuments1?.forEach((element: any) => {
                        if (element.Pws_Doc_estado == '1') valordocumentos[Number(element.Pws_Doc_Tipo) - 1] = element;
                    });

                    const resultados = await Promise.all(await obtenerDocumentosGaranGenerados(valordocumentos));
                    const resultados2 = await Promise.all(await obtenerDocumentosGaranCargadosPorVendedor(valordocumentos));

                    // Asignar los resultados a valordocumentos
                    resultados.forEach((resultado, index) => {
                        if (resultado) {
                            valordocumentos[index] = resultado;
                        }
                    });
                    resultados2.forEach((resultado, index) => {
                        if (resultado) {
                            valordocumentos2[index] = resultado;
                        }
                    });

                // Actualizar el estado con los documentos obtenidos
                setDocuments(valordocumentos);
                setDocuments2(valordocumentos2);

                
            }
        }
        
        fun();
    }, [valorDocuments, refresh]);

    const [revisado, setRevisado] = React.useState(true);
    const cambiarUpdat = () => {
        if (update) {
            valorDocuments.valorDocuments.Busquedatotal();
        }
        setUpdate(!update);
    };
    const cambiarUpdateDocuLega = () => {
        if (UpdateDocumentosLegales) {
            valorDocuments.valorDocuments.Busquedatotal();
        }
        setUpdateDocumentosLegales(!UpdateDocumentosLegales);
    };
    const uploadFtp = (e: any) => {
        const target = e.target;
        let condigoDocumento = String(sessionStorage.getItem('cod'));
        let num_solicitud = String(sessionStorage.getItem('num_sol'));
        try {
            if (target.files && target.files[0]) {
                const maxAllowedSize = 5 * 1024 * 1024;
                if (target.files[0].size > maxAllowedSize) {
                    toast.error('Seleccione un archivo de tamaño máximo 5mb.');
                    target.value = '';
                }
                if (condigoDocumento == '0') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Pagare_' + num_solicitud + '_29_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '1') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Cont_aval_' + num_solicitud + '_47_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    console.dir(solicitud);
                    solicitud
                        .then((response) => {
                            console.dir(response);
                            // Manejo de éxito de la petición

                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                    // Mostrar mensaje de error genéric
                }
                if (condigoDocumento == '2') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Garant_mobi_' + num_solicitud + '_51_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición
                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '3') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Compraventa_' + num_solicitud + '_52_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición

                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '4') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Dacion_pag_' + num_solicitud + '_53_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición
                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '5') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Cond_Fin_' + num_solicitud + '_54_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición

                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '6') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'Acta_retencion_vehiculo_' + num_solicitud + '_55_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición
                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '7') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'A_entregaParcial_' + num_solicitud + '_56_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición

                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
                if (condigoDocumento == '8') {
                    let file: any = '';
                    file = e.target.files[0];
                    let Pws_Identificacion = valorDocuments.valorDocuments.valor[1];
                    let fileName = file.name;
                    const nuevoNombreArchivo = 'compra _venta _autentificado_' + num_solicitud + '_57_F.pdf';
                    // file = new File([file], nuevoNombreArchivo, { type: file.type });
                    const solicitud = WLOCreditAPIService.addDocumentsFTP(file, Pws_Identificacion, nuevoNombreArchivo);
                    solicitud
                        .then((response) => {
                            // Manejo de éxito de la petición
                            // Mostrar mensaje de éxito utilizando react-toastify
                            toast.success('El archivo se cargó correctamente.');
                            sessionStorage.setItem("uploadedDocuments", "false");
                            setRefresh((prev) => !prev);
                        })
                        .catch((error) => {
                            console.error(error);
                            // Manejo de error de la petición

                            if (error.response && error.response.status === 500) {
                                // Mostrar mensaje de error para el código de estado 500
                                toast.error('Se produjo un error de conexión con el FTP.');
                            } else {
                                // Mostrar mensaje de error genérico
                                toast.error('Se produjo un error de conexión con el FTP.');
                            }
                        });
                }
            }
        } catch (error) {
            toast.error('Se produjo un error de conexion con el ftp'); // messages('error al guardar el archivo')
        }
    };

    const handleFileInputChange = (e: any) => {
        const target = e.target;
        if (target.files && target.files[0]) {
            const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                toast.error('Seleccione un archivo de tamaño máximo 5mb.');
                target.value = '';
            }
        }
        let file: any = '';
        file = e.target.files[0];
        getBase64(file)
            .then(async (result) => {
                const document = result as string;
                const documentRequest = {
                    Pws_num_solicitud: valorDocuments.valorDocuments.valor[0],
                    Pws_Identificacion: valorDocuments.valorDocuments.valor[1],
                    Pws_Tip_Identificacion: valorDocuments.valorDocuments.valor[2],
                    Pws_Doc_Tipo: target.dataset.id,
                    Pws_Doc_nombre: file.name,
                    Pws_Doc_estado: '1',
                    Pws_Doc_ruta: '1',
                    Pws_Clave_doc: document,
                };

                (async () => {
                    const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                    if (Number(solicitud.payload.result) > 0) {
                        toast.success('Subido el Nuevo Archivo');
                    }
                })();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve) => {
            let baseURL: string | ArrayBuffer | null = '';
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    return (
        <div className="box">
            <ul className="title">
                <li>11.</li>
                <li>Documentos de Garantia </li>
                <File />
                {/* <div  onClick={cambiarUpdateDocuLega}> */}
                <div>{/* <Edit/> */}</div>
            </ul>
            <div className="container">
                <div className="row">
                    <div className="col-6 textcenter">
                        <div className="documentsGarantia">
                            <h5>Descargar Documentos</h5>
                            {alldocuments1.map((valorDocumentss: any, index) => {
                                let tipo = '';
                                let documentss = '';
                                let pasar = true;
                                let documento = '';
                                let estaCargado = valorDocumentss.cargado;
                                if (Number(valorDocumentss.Pws_Doc_Tipo) == 6) {
                                    tipo = 'Pagare/carta de instrucciones';
                                    documentss = valorDocumentss.Pws_Doc_nombre;                      
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 7) {
                                    tipo = 'Contrato aval';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 8) {
                                    tipo = 'Garantia mobiliaria';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 9) {
                                    tipo = 'Compraventa';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 10) {
                                    tipo = 'Dación de pago';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 11) {
                                    tipo = 'Condiciones financieras';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 12) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Autorización para la retención de vehículos';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 13) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Acta de entrega parcial';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 14) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Contrato de compraventa autenticado/Otros';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                }
                                if (pasar) {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={ estaCargado
                                                ? 'redBox1 check' : 'redBox1'}
                                                style={{padding: "2px"}}
                                                onClick={(eve: any) => {
                                                    if (UpdateDocumentosLegales) {
                                                        // eve.currentTarget.querySelector('input')?.click()
                                                        documento = valorDocumentss.Pws_Doc_Tipo;
                                                        handleDownloadButtonClick(documento);
                                                    } else {
                                                        if (documentss) {
                                                            if (valorDocumentss && valorDocumentss.Pws_Doc_Tipo) {
                                                                documento = valorDocumentss.Pws_Doc_Tipo;
                                                                handleDownloadButtonClick(documento);
                                                            } else {
                                                                // Manejar el caso en el que valorDocumentss.Pws_Doc_Tipo es undefined
                                                                toast.error('NO SE PUEDE DESCARGAR EL DOCUMENTO');
                                                            }
                                                        }
                                                    }
                                                }}>
                                                {UpdateDocumentosLegales ? (
                                                    <>
                                                        <input
                                                            data-id={valorDocumentss.Pws_Doc_Tipo}
                                                            type="file"
                                                            name="file-input"
                                                            id="file-input"
                                                            className="file-input__input ,"
                                                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                                                            onChange={handleDownloadButtonClick}
                                                        />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {estaCargado ? (
                                                    <>
                                                        <Documentosred />
                                                    </>
                                                ): (
                                                    <>
                                                        <Documentos />
                                                    </>
                                                )}
                                                <p>
                                                    {tipo}
                                                    {UpdateDocumentosLegales ? '' : ''}
                                                </p>
                                            </div>
                                        </>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </div>
                    </div>

                    <div className="col-6 textcenter">
                        <div className="documentsGarantia">
                            <h5>Cargar Documentos</h5>
                            {alldocuments2.map((valorDocumentss: any, index:any) => {
                                let tipo = '';
                                let documentss = '';
                                let pasar = true;
                                let estaCargado = valorDocumentss.cargado;
                                if (Number(valorDocumentss.Pws_Doc_Tipo) == 6) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Pagare/carta de instrucciones';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 7) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Contrato aval';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 8) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Garantia mobiliaria';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 9) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Compraventa';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 10) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Dación de pago';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 11) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Condiciones financieras';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 12) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Autorización para la retención de vehículos';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 13) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Acta de entrega parcial';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 14) {
                                    sessionStorage.setItem('cod_documento', valorDocumentss.Pws_Doc_Tipo);
                                    tipo = 'Contrato de compraventa autenticado/Otros';
                                    documentss = valorDocumentss.Pws_Doc_nombre;
                                }

                                const download = () => {
                                    const link = document.createElement('a');
                                    link.href = valorDocumentss.Pws_Clave_doc;
                                    link.setAttribute('download', documentss);
                                    link.setAttribute('target', '_blank');
                                    // Append to html link element page
                                    document.body.appendChild(link);

                                    // Start download
                                    link.click();
                                    link.remove();
                                };
                                if (pasar) {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={estaCargado ? 'redBox1 check' : 'redBox1'}
                                                onClick={(eve: any) => {
                                                    if (UpdateDocumentosLegales) {
                                                        eve.currentTarget.querySelector('input')?.click();
                                                    }
                                                    let cod = String(index);
                                                    sessionStorage.setItem('cod', cod);
                                                }}>
                                                {UpdateDocumentosLegales ? (
                                                    <>
                                                        <input
                                                            data-id={valorDocumentss.Pws_Doc_Tipo}
                                                            type="file"
                                                            name="file-input"
                                                            id="file-input"
                                                            className="file-input__input"
                                                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                                                            onChange={uploadFtp}
                                                        />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {estaCargado ? (
                                                    <>
                                                        <Documentosred />
                                                    </>
                                                ): (
                                                    <>
                                                        <Documentos />
                                                    </>
                                                )}
                                                <p>
                                                    {tipo}
                                                </p>
                                               {/*  <p>
                                                 {estaCargado ? '' : ''}
                                                </p> */}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    function handleDownloadButtonClick(documento: any) {
        const credencialFtp= process.env.REACT_APP_CREDENCIAL_FTP;
        const personaRequest = {
            Pws_num_solicitud: valorDocuments.valorDocuments.infoCredito.Pws_num_solicitud,
            Pws_Identificacion: valorDocuments.valorDocuments.infoCredito.Pws_Identificacion,
        };
       // Reemplaza con tu contraseña
        const headers = new Headers();
        headers.set('Authorization', 'Basic '+credencialFtp);
        headers.set('Accept', '*/*');
        //     ACA SE DEJA UN TEST PARA PETICION A APACHE
        //     fetch('').then(response => {
        //         let nombre = 'Pagare'
        //         fetch('http://20.110.62.105/garantias/documentos_wlo/'+personaRequest.Pws_Identificacion+'/'+nombre+'_'+personaRequest.Pws_num_solicitud+'.pdf').then(response => {
        //         // manejar la respuesta del servidorhttp://20.110.62.105/garantias/documentos_wlo/
        //         // convierte la respuesta en un objeto blob y crea un enlace para descargar
        //         response.blob().then(blob => {
        //           const url = window.URL.createObjectURL(blob);
        //           const a = document.createElement('a');
        //           a.href = url;
        //           a.download = 'prueba.txt';
        //           document.body.appendChild(a);
        //           a.click();
        //           a.remove();
        //         })
        //     });
        // })

        // }
        //   }
        if (documento == 6) {
            let nombre = 'Pagare';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_29' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_29' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });           
        }
        if (documento == 7) {
            let nombre = 'Cont_aval';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_47' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_47' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 8) {
            let nombre = 'Garant_mobi';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_51' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_51' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 9) {
            let nombre = 'Compraventa';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_52' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_52' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 10) {
            let nombre = 'Dacion_pag';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_53' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_53' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 11) {
            let nombre = 'Cond_Fin';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_54' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_54' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 12) {
            let nombre = 'Acta_retencion_vehiculo';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_55' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_55' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 13) {
            let nombre = 'A_entregaParcial';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_56' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_56' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
        if (documento == 14) {
            let nombre = 'compra_venta_autentificado_';
            fetch(
                process.env.REACT_APP_DOCUMENT +
                    personaRequest.Pws_Identificacion +
                    '/' +
                    nombre +
                    '_' +
                    personaRequest.Pws_num_solicitud +
                    '_57' +
                    '.pdf', { headers }
            ).then((response) => {
                // manejar la respuesta del servidor
                // convierte la respuesta en un objeto blob y crea un enlace para descargar
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = nombre + '_' + personaRequest.Pws_num_solicitud + '_57' + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        }
    }
};
